import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { TwitterOauth2PopupContext } from "./useTwitterOauth2Popup"

import { toast } from "react-toastify"

import api, { API_URL } from "../utils/api/api"

const callbackUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port.length > 0 ? ":" + window.location.port : ""}/brokers-globe`;

export function TwitterOauth2PopupProvider(
    { children }: any
) {

    const [oauthSetupResult, setsOauthSetupResult] = useState<{ success: boolean, error?: string } | undefined>();
    // const [twitterCode, setTwitterCode] = useState<string>();
    const externalWindow = useRef<Window | null>(null);

    const title = "Wolves Brokerage Twitter"
    const width = 500
    const height = 800

    useEffect(() => {
        return () => {
            if (externalWindow.current !== null) externalWindow.current.close();
        }
    }, [])


    const createPopup = (url: string) => {
        const left = window.screenX + (window.outerWidth - width) / 2;
        const top = window.screenY + (window.outerHeight - height) / 2.5;
        const windowFeatures = `toolbar=0,scrollbars=1,status=1,resizable=0,location=1,menuBar=0,width=${width},height=${height},top=${top},left=${left}`;

        const externalPopup = window.open(
            url,
            title,
            windowFeatures,
        );
        return externalPopup;
    };

    const openPopup = async () => {
        // const getAuthUrlResponse = await api.auth.twitter.twitterOauth2GetAuthUrl(callbackUrl);
        // if (getAuthUrlResponse === null || !getAuthUrlResponse.success) {
        //     toast.error("An error occured, try again later.");
        //     return;
        // }
        setsOauthSetupResult(undefined)
        const url = API_URL + `/auth/twitter/oauth1/redirectAuthUrl?callbackUrl=${callbackUrl}`;
        externalWindow.current = createPopup(url);

        const storageListener = () => {
            try {
                let storageTwitterOauth1Data = localStorage.getItem("twitterOauth1Data") as any;
                if (storageTwitterOauth1Data !== null) {
                    console.warn("Detected twitter code")
                    storageTwitterOauth1Data = JSON.parse(storageTwitterOauth1Data);
                    // handle twitterCode
                    finalizeOauth1Connection(storageTwitterOauth1Data.oauth_token, storageTwitterOauth1Data.oauth_verifier)
                    localStorage.removeItem("twitterOauth1Data")

                    window.removeEventListener("storage", storageListener);
                }
                /// Oauth 2
                // let storageTwitterOauth2Data = localStorage.getItem("twitterOauth2Data") as any;
                // if (storageTwitterOauth2Data !== null) {
                //     console.warn("Detected twitter code")
                //     storageTwitterOauth2Data = JSON.parse(storageTwitterOauth2Data);
                //     // handle twitterCode
                //     finalizeOauth2Connection(storageTwitterOauth2Data.state, storageTwitterOauth2Data.code)
                //     localStorage.removeItem("twitterOauth2Data")

                //     window.removeEventListener("storage", storageListener);
                // }
            } catch (e) {
                window.removeEventListener("storage", storageListener);
            }
        }

        window.addEventListener("storage", storageListener);

        // externalWindow.current!.addEventListener('beforeunload', () => {
        //     this.props.onClose()
        // }, false);
    };

    async function finalizeOauth1Connection(oauth_token: string, oauth_verifier: string) {
        const response = await api.auth.twitter.twitterOauth1Connect(oauth_token, oauth_verifier);
        setsOauthSetupResult(response)
        if (response === null || !response.success) {
            toast.error("An error occured while updating permissions.")
            return;
        }
        toast.success("Updated App Permissions.")
    }

    // async function finalizeOauth2Connection(state: string, code: string) {
    //     const response = await api.auth.twitter.twitterOauth2Connect(state, code, callbackUrl);
    //     setsOauth2SetupResult(response)
    //     if (response === null || !response.success) {
    //         toast.error("An error occured while updating permissions.")
    //         return;
    //     }
    //     toast.success("Updated App Permissions.")
    // }

    return (
        <TwitterOauth2PopupContext.Provider
            value={{
                oauthSetupResult,
                openPopup,
            }}
        >
            {children}
        </TwitterOauth2PopupContext.Provider>
    );
}
