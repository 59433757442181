import "./LoadingUserContainer.css"
import React, { FC, useEffect, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";

import gsap from "gsap";

import brokerageEmblem from "../../Images/Icons/brokerageEmblem.png"
import loadingIconAnim from "../../Images/Icons/loadingIcon.gif";
import { useUser } from "../../Components/Connection/useUser";

const LoadingUserContainer: FC<any> = (props) => {

    const { loggingIn } = useUser();

    useEffect(() => {
        if (loggingIn) {
            gsap.set("#loading-user-container", { opacity: 1 })
        } else {
            gsap.to("#loading-user-container", { opacity: 0, duration: 2 })
            gsap.set("#loading-user-container", { pointerEvents: "none" });
            gsap.set("#loading-user-container", { display: "none", delay: 2 })
        }
    }, [loggingIn])

    return (
        <div id="loading-user-container" className="flex-column align-items-center">
            <img src={brokerageEmblem} width="125px" height="auto" />
            <h4 className="text-luxury mt-2">Westmont Capital Brokerage</h4>
            <img src={loadingIconAnim} width="30px" height="auto" className="loading-icon mt-3" />
        </div>
    )
}

export default LoadingUserContainer;