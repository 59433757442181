import "./Home.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { isMobile } from 'react-device-detect';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

import musicIcon from "../../Images/Icons/musicIcon.png";
import musicIconMute from "../../Images/Icons/musicIconMute.png";

import ReactPlayer from 'react-player/youtube'

import Navbar from "../../Components/Navbar"

import WcLogo from "../../Images/Icons/logo.png"
import titleAnimation from "../../Videos/titleAnimation.mp4"
import titleAnimationMobile from "../../Videos/titleAnimationMobile.mp4"
import enterAnimationLoop from "../../Videos/enterAnimationLoop.mp4"
import ArrowRight from "../../Images/Icons/arrowRight.png"

import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

// Entry container Media
import EntryContainerBackground from "../../Images/Home/EntryContainer/cityBackground.webp"
import EntryContainerBackgroundLights from "../../Images/Home/EntryContainer/cityLights.png"
import EntryContainerRestaurant from "../../Images/Home/EntryContainer/restaurant.png"
import EntryContainerForeground from "../../Images/Home/EntryContainer/tablesForeground.png"
import EntryContainerFullRender from "../../Images/Home/EntryContainer/fullRender.webp"


// Main Building media
// import WcTowerBackground from "../../Images/Home/WcTower/TiniedWebp/Bg.webp"
// import WcTowerCloudsBack from "../../Images/Home/WcTower/TiniedWebp/CloudsBack.webp"
// import WcTowerCloudsDown from "../../Images/Home/WcTower/TiniedWebp/CloudsDown.webp"
// import WcTowerCloudsTop from "../../Images/Home/WcTower/TiniedWebp/CloudsTop.webp"
// import WcTowerTransitionClouds from "../../Images/Home/WcTower/TiniedWebp/CloudsTransition.webp"
import WcTowerTower from "../../Images/Home/WcTower/TiniedWebp/Tower.webp"
import WcTowerStreet from "../../Images/Home/WcTower/TiniedWebp/StreetShort.webp"
import WcTowerStreetLights from "../../Images/Home/WcTower/TiniedWebp/StreetLightsShort.webp"
import WcTowerEntry from "../../Images/Home/WcTower/TiniedWebp/TowerEntryShort.webp"
import WcTowerBirdsLoop from "../../Images/Home/WcTower/birdsLoopGif.gif";
import WcTowerFullRender from "../../Images/Home/WcTower/TiniedWebp/fullRender.webp"

import scribble1 from "../../Images/Home/WcTower/scribble1.png"
import scribble2 from "../../Images/Home/WcTower/scribble2.png"
import scribble3 from "../../Images/Home/WcTower/scribble3.png"
import scribble4 from "../../Images/Home/WcTower/scribble4.png"

import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

const Home: FC<any> = (props) => {
    const { musicInfo, setMusicInfo } = props

    const [loadingImagesStatus, setLoadingImagesStatus] = useState({ loading: true, numToLoad: isMobile ? 3 : 9 })
    const loadedImagesCounter = useRef(0)

    const [hasEntered, setHasEntered] = useState(false)

    const towerMainImageRef = useRef<HTMLImageElement>(null)

    const mainBuildingLevel4 = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setMusicInfo({ ...musicInfo, isPlaying: hasEntered })
        if (hasEntered) {
            animateInteriorRestaurantContainer()
            setTimeout(animateTowerContainer, 200)
        }
    }, [hasEntered])

    function handleImageLoad() {
        loadedImagesCounter.current = loadedImagesCounter.current + 1
        // console.log(`Image Loaded num ${loadedImagesCounter.current}`)
        setLoadingImagesStatus({ ...loadingImagesStatus, loading: !(loadedImagesCounter.current >= loadingImagesStatus.numToLoad) })
    }

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        animateEntryContainer()
    }, [])

    useEffect(() => {
        if (!loadingImagesStatus.loading) {
            gsap.from("#entry-container-interact-info", { opacity: 0, duration: .5 })
        }
    }, [loadingImagesStatus.loading])

    function animateEntryContainer() {
        gsap.from("#entry-container-interact", { opacity: 0, duration: 1, delay: 5 })
    }

    function animateInteriorRestaurantContainer() {
        const interiorRestaurantTimeline = gsap.timeline();

        interiorRestaurantTimeline.from("#interior-restaurant-title-container .interior-restaurant-title.first", { scale: 10, opacity: 0, duration: 0.2 }, "+=0.5")
        interiorRestaurantTimeline.from("#interior-restaurant-title-container .interior-restaurant-title.second", { scale: 10, opacity: 0, duration: 0.2 }, ">+50%")
        interiorRestaurantTimeline.from("#interior-restaurant-title-container .interior-restaurant-title.third", { scale: 10, opacity: 0, duration: 0.2 }, ">+50%")

        interiorRestaurantTimeline.from("#interior-restaurant-container #project-description", { opacity: 0, duration: 2 }, ">")

        const leaveInteriorRestaurantTimeline = gsap.timeline({ scrollTrigger: { trigger: "#home", start: "top top", end: "+=1500px", pin: "#interior-restaurant-container", anticipatePin: 1, scrub: true } })
        leaveInteriorRestaurantTimeline.to("#interior-restaurant-title-container", { y: "-100vh" })
        leaveInteriorRestaurantTimeline.to("#project-description", { y: "-100vh" }, "<")
        leaveInteriorRestaurantTimeline.to("#interior-restaurant-container .parallax-container", { y: "-50vh" }, "<")
    }

    function animateTowerContainer() {
        const containers = [
            { pin: "#main-building-content .content-container.penthouse-level", start: "3.92% 60%", end: "28.92% 60%", fadeIn: true, fadeOut: true },
            { pin: "#main-building-content .content-container.offices-level", start: "24.48% 60%", end: "49.48% 60%", fadeIn: true, fadeOut: true },
            { pin: "#main-building-content .content-container.grove-level", start: "50% 60%", end: "75% 60%", fadeIn: true, fadeOut: true },
            { pin: "#main-building-content .content-container.entrance-level", start: "72% 60%", end: "100% 60%", fadeIn: true, fadeOut: false },
        ]

        containers.forEach((container) => {
            const containerTimeline = gsap.timeline({
                scrollTrigger: {
                    trigger: "#main-building-content",
                    start: container.start,
                    end: container.end,
                    scrub: true,
                    pin: container.pin,
                    markers: false
                }
            })
            if (container.fadeIn) containerTimeline.fromTo(container.pin, { opacity: 0 }, { opacity: 1, duration: 1 })
            if (container.fadeOut) containerTimeline.to(container.pin, { opacity: 0 })
        })
    }

    const homeContent = <>
        <Navbar />
        <div id="interior-restaurant-container" className="d-flex">
            <div id="interior-restaurant-media" className="parallax-container">
                {isMobile ?
                    <>
                        <img src={EntryContainerFullRender} onLoad={handleImageLoad} />
                        {/* <img src={EntryContainerBackgroundLights} onLoad={handleImageLoad} />
                        <img src={EntryContainerRestaurant} onLoad={handleImageLoad} />
                        <img src={EntryContainerForeground} onLoad={handleImageLoad} /> */}
                    </>
                    : <>
                        <MouseParallaxChild factorX={0.05} factorY={0.10}>
                            <img src={EntryContainerBackground} onLoad={handleImageLoad} />
                        </MouseParallaxChild>
                        <MouseParallaxChild factorX={0.10} factorY={0.15}>
                            <img src={EntryContainerBackgroundLights} onLoad={handleImageLoad} />
                        </MouseParallaxChild>
                        <MouseParallaxChild factorX={0.15} factorY={0.20}>
                            <img src={EntryContainerRestaurant} onLoad={handleImageLoad} />
                        </MouseParallaxChild>
                        <MouseParallaxChild factorX={0.20} factorY={0.25}>
                            <img src={EntryContainerForeground} onLoad={handleImageLoad} />
                        </MouseParallaxChild>
                    </>}
            </div>
            <div id="project-description" className="d-flex">
                Wolves of Ether St is a cult of traders, investors and degens striving for success on the Ethereum blockchain. A community built around learning and sharing knowledge about the crypto markets while earning rewards.
            </div>
            <div id="interior-restaurant-title-container">
                <h1 className="interior-restaurant-title first">We're</h1>
                <h1 className="interior-restaurant-title second">Not</h1>
                <h1 className="interior-restaurant-title third">Leaving</h1>
            </div>
        </div>
    </>

    const towerContent = <>
        <div id="main-building-container" className="d-flex" style={{ height: towerMainImageRef.current !== null ? towerMainImageRef.current.height : 0 }}>
            <div id="main-building-media" className="parallax-container" style={{ opacity: 1 }}>
                <div style={{ opacity: 1 }}>
                    {isMobile ? <img src={WcTowerFullRender} onLoad={handleImageLoad} ref={towerMainImageRef} style={{ top: 0 }} />
                        :
                        <>
                            <MouseParallaxChild factorX={0.04} factorY={0.00} style={{ zIndex: 2 }}>
                                <img src={WcTowerTower} onLoad={handleImageLoad} ref={towerMainImageRef} style={{ top: 0 }} />
                            </MouseParallaxChild>

                            <MouseParallaxChild factorX={0.1} factorY={0.12} style={{ zIndex: 6 }} className="tower-bottom-image-container">
                                <img src={WcTowerEntry} onLoad={handleImageLoad} style={{ bottom: 0 }} />
                            </MouseParallaxChild>
                            <MouseParallaxChild factorX={0.15} factorY={0.12} style={{ zIndex: 7 }} className="tower-bottom-image-container">
                                <img src={WcTowerStreet} onLoad={handleImageLoad} style={{ bottom: 0 }} />
                            </MouseParallaxChild>
                            <MouseParallaxChild factorX={0.18} factorY={0.12} style={{ zIndex: 8 }} className="tower-bottom-image-container">
                                <img src={WcTowerStreetLights} onLoad={handleImageLoad} style={{ bottom: 0 }} />
                            </MouseParallaxChild>
                        </>
                    }
                </div>
            </div>

            <img id="tower-birds" src={WcTowerBirdsLoop} onLoad={handleImageLoad} />

            <div id="main-building-content" className="d-flex flex-column">
                <div className="content-container penthouse-level d-flex flex-column">
                    <h1 className="text-luxury text-letter-blocks">Brokerage Program</h1>
                    <img src={scribble1} />
                </div>
                <div className="content-container offices-level d-flex flex-column align-items-end" style={{ left: "unset" }}>
                    <h1 className="text-luxury text-letter-blocks">Alpha Club Platform</h1>
                    <img src={scribble2} />
                </div>
                <div className="content-container grove-level d-flex flex-column">
                    <h1 className="text-luxury text-letter-blocks">Wolves NFT Collection</h1>
                    <img src={scribble3} />
                </div>
                <div className="content-container entrance-level d-flex flex-column align-items-end" style={{ left: "unset" }}>
                    <h1 className="text-luxury text-letter-blocks">Education & Financial Tools</h1>
                    <img src={scribble4} />
                </div>
            </div>
        </div>
    </>

    return (
        <div id="home" className="d-flex flex-column">
            <div id="entry-container" className={`flex-column justify-content-center align-items-center ${!loadingImagesStatus.loading ? "hover-pointer" : ""}`} onClick={() => !loadingImagesStatus.loading && setHasEntered(true)} style={{ display: !hasEntered || loadingImagesStatus.loading ? "flex" : "none" }}>
                <div id="entry-container-main" className="d-flex flex-column align-items-center">
                    <video src={window.innerWidth > 998 ? titleAnimation : titleAnimationMobile} muted={true} autoPlay={true} playsInline={true} />
                </div>
                <div id="entry-container-interact" className="d-flex justify-content-center align-items-center my-auto">
                    {!loadingImagesStatus.loading ? <div id="entry-container-interact-info" className="d-flex justify-content-center w-100">
                        <video src={enterAnimationLoop} muted={true} autoPlay={true} loop={true} playsInline={true} />
                    </div>
                        : <h2 className="text-luxury">Loading...</h2>}
                </div>
            </div>
            <div id="home-content" className="flex-column" style={{ display: !hasEntered || loadingImagesStatus.loading ? "none" : "flex" }}>
                {isMobile ?
                    <div className="d-flex flex-column">
                        {homeContent}
                    </div>
                    : <MouseParallaxContainer className="d-flex flex-column" resetOnLeave={false}>
                        {homeContent}
                    </MouseParallaxContainer>
                }

                {isMobile ?
                    <div className="d-flex flex-column">
                        {towerContent}
                    </div>
                    : <MouseParallaxContainer className="d-flex flex-column" globalFactorY={0} resetOnLeave={false}>
                        {towerContent}
                    </MouseParallaxContainer>
                }
            </div>

            {hasEntered ? <><ReactPlayer id="music-player" url='https://www.youtube.com/watch?v=9Ri7TcukAJ8' playing={hasEntered} loop={true} playsInline={true} volume={musicInfo.volume} muted={musicInfo.isMuted} />
                <div id="music-controller" onMouseEnter={() => setMusicInfo({ ...musicInfo, controlsSelected: true })} onMouseLeave={() => setMusicInfo({ ...musicInfo, controlsSelected: false })}>
                    <button className="btn-simple" onClick={() => setMusicInfo({ ...musicInfo, isMuted: !musicInfo.isMuted })}><img src={musicInfo.isMuted ? musicIconMute : musicIcon} /></button>
                </div></> : null}
        </div >
    )
}

export default Home