import "./BrokerageAccountPoints.css"
import React, { FC, useEffect, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { TwitterTimelineEmbed } from "react-twitter-embed";

import { TwitterOauth2PopupProvider } from "../../../Components/TwitterOauth2PopupProvider"
import BrokerageDailyGm from "./BrokerageDailyGm";
import BrokerageCheckTweets from "./BrokerageCheckTweets";

import loadingIconTransparentAnim from "../../../Images/Icons/loadingIconTransparent.gif";

import { animateLoadingSquares } from "../../../utils/utils"
import { useUser } from "../../../Components/Connection/useUser";

const BrokerageAccountPoints: FC<any> = (props) => {

    const { currentUserBrokerageInfoFetch } = useUser();

    useEffect(() => {
        if (currentUserBrokerageInfoFetch.currentUserBrokerageInfo === null) animateLoadingSquares("brokerage-account-points", currentUserBrokerageInfoFetch.fetch());
    }, [])

    const twitterTimeline = useMemo(() => {
        return <TwitterTimelineEmbed
            onLoad={function noRefCheck() { }}
            options={{
                height: 500,
                width: "100%"
            }}
            screenName="WolfofEtherSt"
            sourceType="profile"
            theme="dark"
            placeholder={<img src={loadingIconTransparentAnim} className="loading-icon-anim mx-auto" style={{ width: "40px" }} />}
        />
    }, [])

    return (
        <div id="brokerage-account-points" className="d-flex flex-column text-dark w-100 mt-4 px-2 px-md-4 px-lg-5 pb-5">
            <h1 className="text-luxury">Earn Points</h1>
            <div className="d-flex flex-column flex-xxl-row justify-content-between align-items-center align-items-xxl-start w-100 mt-5">
                <div id="daily-gm-container" className="d-flex justify-content-center col-12 col-sm-11 col-md-10 col-xxl-3 h-fit p-3 mt-4 mt-xxl-0">
                    <TwitterOauth2PopupProvider>
                        <BrokerageDailyGm />
                    </TwitterOauth2PopupProvider>
                </div>
                <div id="project-twitter-timeline-container" className="d-flex flex-column justify-content-center align-items-center col-12 col-sm-11 col-md-10 col-xxl-5 p-3 mt-4 mt-xxl-0">
                    <h3 className="text-normal">Interact with our Twitter</h3>
                    {twitterTimeline}
                    {/* <img src={loadingIconAnim} className="loading-icon-anim mx-auto" style={{ width: "40px", filter: "invert(1)" }} /> */}
                </div>
                <div id="check-tweets-container" className="d-flex col-12 col-sm-11 col-md-10 col-xxl-3 justify-content-center h-fit p-3 mt-4 mt-xxl-0">
                    <BrokerageCheckTweets className="align-items-center align-items-xxl-end" />
                </div>
            </div>
        </div>
    )
}

export default BrokerageAccountPoints;