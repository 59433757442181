import "./ArtContent.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import Navbar from "../../Components/Navbar";
import ArtContentMedia from "./ArtContentMedia";

import letMeExplainMeme from "../../Images/ArtContent/Memes/letMeExplain.png";
import notLeavingCelebrationMeme from "../../Images/ArtContent/Memes/notLeavingCelebration.png";
import sellMeThisMeme from "../../Images/ArtContent/Memes/sellMeThis.png";
import swissBankMeme from "../../Images/ArtContent/Memes/wolfSwissBank.png";
import wolfBillsMeme from "../../Images/ArtContent/Memes/wolfBills.png";
import wolfBittingMeme from "../../Images/ArtContent/Memes/wolfBitting.gif";
import wolfFUMeme from "../../Images/ArtContent/Memes/wolfFU.jpg";
import wolfHighMeme from "../../Images/ArtContent/Memes/donnieFuckedUp.gif";
import wolfChestThumpMeme from "../../Images/ArtContent/Memes/Wolf-ChestThump.gif";
import wolfMonkeyMeme from "../../Images/ArtContent/Memes/wolfMonkey.png";
import wolfPointingMeme from "../../Images/ArtContent/Memes/wolfPointing.gif";
import wolfYachtCheersMeme from "../../Images/ArtContent/Memes/wolfYachtCheers.gif";
import enteringBrokerageMeme from "../../Images/ArtContent/Memes/enteringBrokerage.gif"

import funCouponsMeme from "../../Images/ArtContent/Memes/funCoupons.gif";
import batemanMadMeme from "../../Images/ArtContent/Memes/batemanMad.gif";
import batemanThinkingMeme from "../../Images/ArtContent/Memes/batemanThinking.gif";
// import notLeavingVideoMeme from "../../Images/ArtContent/Memes/notLeavingVideo.mp4"

import MondayGM from "../../Images/ArtContent/GMs/GM-Monday.gif";
import TuesdayGM from "../../Images/ArtContent/GMs/GM-Tuesday.gif";
import WednesdayGM from "../../Images/ArtContent/GMs/GM-Wednesday.gif";
import ThursdayGM from "../../Images/ArtContent/GMs/GM-Thursday.gif";
import FridayGM from "../../Images/ArtContent/GMs/GM-Friday.gif";
import SaturdayGM from "../../Images/ArtContent/GMs/GM-Saturday.gif";
import SundayGM from "../../Images/ArtContent/GMs/GM-Sunday.gif";

import BrokersTable from "../../Images/ArtContent/Illustrations/BrokersTable.png";
import TwitterBanner from "../../Images/ArtContent/Illustrations/TwitterBanner.png";
import WCPoster from "../../Images/ArtContent/Illustrations/Westmont Capital poster.png";
import TrailerAnimGif1 from "../../Images/ArtContent/Illustrations/Trailer-Anim-GIF1.gif";
import TrailerAnimGif2 from "../../Images/ArtContent/Illustrations/Trailer-Anim-GIF2.gif";
import TowerEntrance from "../../Images/ArtContent/Illustrations/TowerEntrance.png";
import Penthouse from "../../Images/ArtContent/Illustrations/Penthouse.png";


const ArtContent: FC<any> = (props) => {
    return (
        <div id="art-content" className="d-flex flex-column">
            <Navbar transparent="true" fillOnScrollUp="true" />

            <div id="art-content-memes" className="d-flex">
                <ArtContentMedia media={swissBankMeme} mediaType="image" containerClass="meme-media-container container-1" containerStyle={{ width: "67%" }} mediaFilePath="Memes/wolfSwissBank.png" />
                <ArtContentMedia media={wolfChestThumpMeme} mediaType="image" containerClass="meme-media-container container-2" containerStyle={{ width: "33%" }} mediaFilePath="Memes/Wolf-ChestThump.gif" />

                <div className="d-flex flex-wrap" style={{ width: "46%", height: "auto" }}>
                    <ArtContentMedia media={wolfMonkeyMeme} mediaType="image" containerClass="meme-media-container container-3" containerStyle={{ width: "50%" }} mediaFilePath="Memes/wolfMonkey.png" />
                    <ArtContentMedia media={wolfHighMeme} mediaType="image" containerClass="meme-media-container container-4" containerStyle={{ width: "50%" }} mediaFilePath="Memes/donnieFuckedUp.png" />

                    <ArtContentMedia media={enteringBrokerageMeme} mediaType="image" containerClass="meme-media-container container-5" containerStyle={{ width: "100%", marginTop: "auto" }} mediaFilePath="Memes/enteringBrokerage.gif" />
                </div>
                <ArtContentMedia media={letMeExplainMeme} mediaType="image" containerClass="meme-media-container container-6" containerStyle={{ width: "54%" }} mediaFilePath="Memes/letMeExplain.png" />

                <ArtContentMedia media={wolfPointingMeme} mediaType="image" containerClass="meme-media-container container-7" containerStyle={{ width: "27.5%" }} mediaFilePath="Memes/wolfPointing.gif" />
                <ArtContentMedia media={wolfBittingMeme} mediaType="image" containerClass="meme-media-container container-8" containerStyle={{ width: "45%" }} mediaFilePath="Memes/wolfBitting.gif" />
                <ArtContentMedia media={wolfBillsMeme} mediaType="image" containerClass="meme-media-container container-9" containerStyle={{ width: "27.5%" }} mediaFilePath="Memes/wolfBills.png" />

                <ArtContentMedia media={notLeavingCelebrationMeme} mediaType="image" containerClass="meme-media-container container-10" containerStyle={{ width: "60%" }} mediaFilePath="Memes/wolfHigh.png" />
                <ArtContentMedia media={wolfYachtCheersMeme} mediaType="image" containerClass="meme-media-container container-11" containerStyle={{ width: "40%" }} mediaFilePath="Memes/wolfYachtCheers.gif" />

                <ArtContentMedia media={batemanMadMeme} mediaType="image" containerClass="meme-media-container container-12" containerStyle={{ width: "30%" }} mediaFilePath="Memes/batemanMad.gif" />
                <ArtContentMedia media={funCouponsMeme} mediaType="image" containerClass="meme-media-container container-13" containerStyle={{ width: "45%" }} mediaFilePath="Memes/funCoupons.gif" />
                <ArtContentMedia media={batemanThinkingMeme} mediaType="image" containerClass="meme-media-container container-14" containerStyle={{ width: "25%" }} mediaFilePath="Memes/batemanThinking.png" />


            </div>
            <div id="art-content-gms" className="d-flex">
                <ArtContentMedia media={MondayGM} mediaType="image" containerClass="gm-media-container container-1 p-md-3" containerStyle={{ width: "33.3%" }} mediaFilePath="GMs/GM-Monday.gif" extraComponents={[<h3 className="gm-date-text text-normal text-white w-fit">Monday</h3>]} />
                <ArtContentMedia media={TuesdayGM} mediaType="image" containerClass="gm-media-container container-2 p-md-3" containerStyle={{ width: "33.3%" }} mediaFilePath="GMs/GM-Tuesday.gif" extraComponents={[<h3 className="gm-date-text text-normal text-white w-fit">Tuesday</h3>]} />
                <ArtContentMedia media={WednesdayGM} mediaType="image" containerClass="gm-media-container container-3 p-md-3" containerStyle={{ width: "33.3%" }} mediaFilePath="GMs/GM-Wednesday.gif" extraComponents={[<h3 className="gm-date-text text-normal text-white w-fit">Wednesday</h3>]} />
                <ArtContentMedia media={ThursdayGM} mediaType="image" containerClass="gm-media-container container-4 p-md-3" containerStyle={{ width: "33.3%" }} mediaFilePath="GMs/GM-Thursday.gif" extraComponents={[<h3 className="gm-date-text text-normal text-white w-fit">Thursday</h3>]} />
                <ArtContentMedia media={FridayGM} mediaType="image" containerClass="gm-media-container container-5 p-md-3" containerStyle={{ width: "33.3%" }} mediaFilePath="GMs/GM-Friday.gif" extraComponents={[<h3 className="gm-date-text text-normal text-white w-fit">Friday</h3>]} />
                <ArtContentMedia media={SaturdayGM} mediaType="image" containerClass="gm-media-container container-6 p-md-3" containerStyle={{ width: "33.3%" }} mediaFilePath="GMs/GM-Saturday.gif" extraComponents={[<h3 className="gm-date-text text-normal text-white w-fit">Saturday</h3>]} />
                <ArtContentMedia media={SundayGM} mediaType="image" containerClass="gm-media-container container-7 p-md-3" containerStyle={{ width: "33.3%" }} mediaFilePath="GMs/GM-Sunday.gif" extraComponents={[<h3 className="gm-date-text text-normal text-white w-fit">Sunday</h3>]} />
            </div>
            <div id="art-content-illustrations" className="d-flex">
                <ArtContentMedia media={BrokersTable} mediaType="image" containerStyle={{ width: "100%" }} />
                <div className="d-flex flex-wrap h-fit" style={{ width: "63.16%" }}>
                    <ArtContentMedia media={TwitterBanner} mediaType="image" containerClass="illustrations-media-container container-1" containerStyle={{ width: "100%", transform: "translate(0px, -15px)", zIndex: 1 }} />
                    <ArtContentMedia media={TrailerAnimGif1} mediaType="image" containerClass="illustrations-media-container container-2" containerStyle={{ width: "50%", transform: "translate(10px, -30px)", zIndex: 2 }} />
                    <ArtContentMedia media={TrailerAnimGif2} mediaType="image" containerClass="illustrations-media-container container-3" containerStyle={{ width: "50%", transform: "translate(0px, 40px)", zIndex: 1 }} />
                </div>

                <ArtContentMedia media={WCPoster} mediaType="image" containerClass="illustrations-media-container container-4" containerStyle={{ width: "36.84%" }} />

                <ArtContentMedia media={TowerEntrance} mediaType="image" containerClass="illustrations-media-container container-5" containerStyle={{ width: "40%", transform: "translateY(-20px)" }} />
                <ArtContentMedia media={Penthouse} mediaType="image" containerClass="illustrations-media-container container-6" containerStyle={{ width: "60%" }} />

            </div>
        </div>
    )
}

export default ArtContent;
