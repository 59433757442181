import axios from "axios";

import { API_URL } from "../api"

const twitterAccountHandle = "WolfofEtherSt"

async function twitterOauth1Connect(oauth_token: string, oauth_verifier: string) {
    let response;
    try {
        response = await axios.get(`${API_URL}/auth/twitter/oauth1/connect`, { params: { oauth_token, oauth_verifier }, withCredentials: true, });
    } catch (error) {
        console.error(error);
        return null;
    }
    const responseData = response.data;

    if (!responseData.success) {
        console.error(response);
    }
    return responseData;
}

// async function twitterOauth2GetAuthUrl(callbackUrl: string) {
//     let response;
//     try {
//         response = await axios.get(`${API_URL}/auth/twitter/oauth2/getAuthUrl`, { params: { callbackUrl }, withCredentials: true, });
//     } catch (error) {
//         console.error(error);
//         return null;
//     }
//     const responseData = response.data;

//     if (!responseData.success) {
//         console.error(response);
//     }
//     return responseData;
// }
// async function twitterOauth2Connect(state: string, code: string, callbackUrl: string) {
//     let response;
//     try {
//         response = await axios.get(`${API_URL}/auth/twitter/oauth2/connect`, { params: { state, code, callbackUrl }, withCredentials: true, });
//     } catch (error) {
//         console.error(error);
//         return null;
//     }
//     const responseData = response.data;

//     if (!responseData.success) {
//         console.error(response);
//     }
//     return responseData;
// }

async function getTwitterInfoFromOauthToken(oauthToken: string, oauthVerifier: string): Promise<any> {
    let response;
    try {
        response = await axios.get(`${API_URL}/auth/twitter/request_access_token?oauthToken=${oauthToken}&oauthVerifier=${oauthVerifier}`, { withCredentials: true });
    } catch (error) {
        console.error(error);
        return null;
    }
    const responseData = response.data;

    if (!responseData.success) {
        console.error(response);
        return responseData;
    }
    return responseData;

    // const infoResponse = await axios.get(`${API_URL}/auth/twitter/info`, {withCredentials: true});
    // const infoResponseData = infoResponse.data

    // return infoResponseData;
}

async function refreshFollowingStatus(): Promise<{ success: boolean, error: string | undefined, isFollowing: boolean | undefined } | null> {
    let response;
    try {
        response = await axios.get(`${API_URL}/auth/twitter/update-following-status`);
    } catch (error) {
        console.error(error);
        return null;
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(response);
    }
    return responseData;
}

export default {
    twitterOauth1Connect,
    // twitterOauth2GetAuthUrl,
    // twitterOauth2Connect,
    getTwitterInfoFromOauthToken,
    refreshFollowingStatus,
}