import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import loadingIconTransparentAnim from "../../../Images/Icons/loadingIconTransparent.gif";

import { CONTENT_USAGE_ANALYSIS } from "../../../utils/constants"
import { delay, getTimeDifferenceFromDelta, transformBucketTimeDifferenceToClock } from "../../../utils/utils"
import api from "../../../utils/api/api"
import { useUser } from "../../../Components/Connection/useUser";


const BrokerageCheckTweets: FC<any> = (props) => {
    const { className, style } = props;

    const history = useHistory();
    const { currentUserBrokerageInfoFetch, setCurrentUserBrokerageInfoFetch } = useUser();

    const [analysingTweets, setAnalysingTweets] = useState(false);
    const [timeNextBeforeNextPossibleAnalysis, setTimeNextBeforeNextPossibleAnalysis] = useState<number>();

    async function checkTweets() {
        if (currentUserBrokerageInfoFetch.currentUserBrokerageInfo === null) return;
        setAnalysingTweets(true)
        const analysisResponse = await api.brokerage.analyzeUserTweets();
        setAnalysingTweets(false);
        if (!analysisResponse.success) {
            toast.error("An error occured while analysing your Tweets.");
            return;
        }

        const currentTimestamp = new Date().getTime();
        if (analysisResponse.newTweetsWithContentUsage.length === 0) toast.info("Did not find any new tweets using our content.")
        else toast.success(`Found ${analysisResponse.newTweetsWithContentUsage.length} new tweets using our content! You earned ${analysisResponse.newSocialTwitterPoints} points.`);
        setCurrentUserBrokerageInfoFetch({ ...currentUserBrokerageInfoFetch, currentUserBrokerageInfo: { ...currentUserBrokerageInfoFetch.currentUserBrokerageInfo, contentUsage: { ...currentUserBrokerageInfoFetch.currentUserBrokerageInfo.contentUsage, tweetsWithContent: currentUserBrokerageInfoFetch.currentUserBrokerageInfo.contentUsage.tweetsWithContent + analysisResponse.newTweetsWithContentUsage.length, previousAnalysisTimestamp: currentTimestamp } } })
    }

    useEffect(() => {
        updateTimeLeftBeforeNextPossibleAnalysis();
    }, [currentUserBrokerageInfoFetch.currentUserBrokerageInfo, timeNextBeforeNextPossibleAnalysis])
    async function updateTimeLeftBeforeNextPossibleAnalysis() {
        if (currentUserBrokerageInfoFetch.currentUserBrokerageInfo !== null) {
            if (timeNextBeforeNextPossibleAnalysis !== 0 && timeNextBeforeNextPossibleAnalysis !== undefined) await delay(1000);
            const diff = currentUserBrokerageInfoFetch.currentUserBrokerageInfo.contentUsage.previousAnalysisTimestamp + CONTENT_USAGE_ANALYSIS.MINIMUM_TIMESPAN_BETWEEN_ANALYSIS - Date.now();
            setTimeNextBeforeNextPossibleAnalysis(Math.max(diff, 0));
        } else {
            setTimeNextBeforeNextPossibleAnalysis(undefined)
        }
    }

    return (
        <div id="check-tweets" className={`d-flex flex-column ${className}`} style={style}>
            <h3 className="text-normal text-end mb-0">Tweet our content</h3>
            <p className="text-center mt-4 mt-xxl-5 mb-3 mb-xxl-4">Create tweets or comments using our memes & illustrations to earn points!</p>
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center w-100">
                <button className="btn-default text-normal-base-size px-3 mb-2 mb-sm-0" onClick={(e) => history.push("/art-content")}>View our Media</button>
                <button id="analyze-tweets-btn" className="btn-simple-fill-reversed text-normal-base-size px-3" disabled={currentUserBrokerageInfoFetch.fetching || analysingTweets || timeNextBeforeNextPossibleAnalysis !== 0} onClick={(e) => checkTweets()}>{analysingTweets ? <img src={loadingIconTransparentAnim} className="loading-icon-anim" /> : timeNextBeforeNextPossibleAnalysis === undefined ? "Analyze your Tweets" : timeNextBeforeNextPossibleAnalysis <= 0 ? "Analyze your Tweets" : transformBucketTimeDifferenceToClock(getTimeDifferenceFromDelta(timeNextBeforeNextPossibleAnalysis), true)}</button>
            </div>
        </div>
    )
}

export default BrokerageCheckTweets;
