import axios from "axios";

import {Leaderboard} from "../../types"
import {delay} from "../../utils"
import {API_URL} from "../api"

async function fetchLeaderboard(
    leaderboardName: string
): Promise<Leaderboard|null> {
    // const data = JSON.stringify({ paper });
    // const customConfig = {
    //     headers: {
    //     'Content-Type': 'application/json'
    //     }
    // };

    // const response = await axios.post(`${API_URL}/papers/postPaper`, data, customConfig);
    // const responseData = response.data;
    // if(!responseData.success) {
    //     console.error(responseData.error);
    //     // return responseData;
    // }
    
    // return responseData;

    const response = await axios.get(`${API_URL}/leaderboards?leaderboardName=${leaderboardName}`);
    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return null
    }

    responseData.leaderboard.lastUpdateTimestamp = responseData.leaderboard.lastUpdateTimestamp._seconds;

    return responseData.leaderboard;
}


export default {
    fetchLeaderboard
}