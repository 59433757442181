import "./BrokerageAccountInvites.css"
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";

import Tree, { withStyles } from '../../../react-vertical-tree'

import BrokerCardInfo from "./BrokerCardInfo"

import api from "../../../utils/api/api";
import { TreeNode, UserAccount } from "../../../utils/types"
import { useUser } from "../../../Components/Connection/useUser";



const BrokerageAccountInvites: FC<any> = (props) => {
    const { setShowInviteBrokerModal, brokerFamilyTreeInfo } = props;

    const { currentUser } = useUser();

    const treeContainerRef = useRef<any>(null)
    const treeRef = useRef<any>(null)
    // useEffect(() => {
    //     // handleTreeScale()
    //     // console.log("Update tree scale")
    // })

    // async function handleTreeScale() {
    //     if (treeRef.current === null) return
    //     await delay(1000)
    //     console.log(treeRef.current.clientWidth)
    //     console.log(treeContainerRef.current.clientWidth)
    //     const scale = (treeContainerRef.current.clientWidth - 100) / treeRef.current.clientWidth
    //     treeRef.current.style.transform = `scale(${scale})`;

    //     treeContainerRef.current.style.height = treeRef.current.clientHeight * scale + "px";
    // }
    const styles = {
        lines: {
            color: '#ffffff',
            height: '90px',
        },
        node: {
            backgroundColor: '#fff',
            //   border: '1px solid #1890ff',
        },
        text: {
            color: '#ccc',
        }
    }
    const TreeComponent = withStyles(styles)(Tree);
    return (
        <div id="brokerage-account-invites" className="d-flex flex-column text-dark w-100 mt-4 px-5 pb-5">
            <h1 className="text-luxury">Invites</h1>
            <div id="brokers-tree" ref={treeContainerRef} className="d-flex flex-column justify-content-center align-items-center w-100 pt-5">
                {brokerFamilyTreeInfo.loading ?
                    <TreeComponent data={[{ root: "1", children: [{ root: "2", children: [] }, { root: "3", children: [] }, { root: "4", children: [] }] }]} direction render={(item: any) => <BrokerCardInfo loadingContainer={true} setShowInviteBrokerModal={setShowInviteBrokerModal} className="loading-card" />} /> // <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>
                    : brokerFamilyTreeInfo.brokerFamilyTree === null ? <h6 className="text-center mb-0"><strong>{currentUser?.userInfo.username}</strong> is not part of any referral tree.</h6> : brokerFamilyTreeInfo.brokerFamilyTree !== undefined ? <TreeComponent reference={treeRef} data={[brokerFamilyTreeInfo.brokerFamilyTree]} direction render={(item: any) => <BrokerCardInfo setShowInviteBrokerModal={setShowInviteBrokerModal} searchedUserId={currentUser!.userId} userAccount={item.root} />} />
                        : null}
            </div>
        </div>
    )
}

export default BrokerageAccountInvites;