import axios from "axios"

import users from "./routes/users"
import brokerage from "./routes/brokerage"
import leaderboards from "./routes/leaderboards"
import royaltyShare from "./routes/royaltyShare"

import auth from "./auth/auth"

// export const API_URL = "http://localhost:3001/v1";
export const API_URL = "https://us-central1-wolves-capital.cloudfunctions.net/api/v1";

export async function get(url: string, errorReturn: any, failureReturn?: any, successReturnKey?: string, params?: any) {
    let response;
    try {
        response = await axios.get(url, { params });
    } catch (error) {
        console.error(error);
        return errorReturn;
    }
    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        if (failureReturn !== undefined) return failureReturn;
    }

    return successReturnKey === undefined ? responseData : responseData[successReturnKey];
}

export async function post(url: string, data: any, config: any, errorReturn: any, failureReturn?: any, successReturnKey?: string) {
    let response;
    try {
        response = await axios.post(url, data, config);
    } catch (error) {
        console.error(error);
        return errorReturn;
    }
    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        if (failureReturn !== undefined) return failureReturn;
    }

    return successReturnKey === undefined ? responseData : responseData[successReturnKey];
}

export default {
    users,
    brokerage,
    leaderboards,
    royaltyShare,

    auth,

    helpers: {
        get,
        post,
    }
}