import "./MainHandler.css"
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';

import { ToastContainer, cssTransition, Zoom } from 'react-toastify';

import Home from "./Pages/Home/Home"
import Brokerage from "./Pages/Brokerage/Brokerage"
import ArtContent from "./Pages/ArtContent/ArtContent"
import BuildWolf from "./Pages/BuildWolf/BuildWolf"

import { delay } from "./utils/utils"

const toastifyTransition = cssTransition({
    enter: "toastify-transition fadeIn",
    exit: "toastify-transition fadeOut",
    // collapseDuration: 500,
    // collapse: false,
});


interface MusicInfo {
    volume: number;
    isMuted: boolean;
    isPlaying: boolean;
    controlsSelected: boolean;
}

const MainHandler: FC<any> = (props) => {

    const location = useLocation()
    const [locationPaths, setLocationPaths] = useState<string[]>([])

    const [musicInfo, setMusicInfo] = useState<MusicInfo>({ volume: .5, isMuted: isMobile, isPlaying: false, controlsSelected: false })

    useEffect(() => {
        console.log(location.pathname)

        // location.pathname.replace(/https:\/\/.+\//, "")
        const paths = location.pathname.slice(1, location.pathname.length).split("/")
        console.log(paths)
        setLocationPaths(paths)

        const windowLocationSearch = queryString.parse(window.location.search);
        const { state, code, oauth_token, oauth_verifier, application } = windowLocationSearch;
        if (code !== null && code !== undefined) {
            delete windowLocationSearch.code;
            delete windowLocationSearch.state;
            delete windowLocationSearch.application;
            if (application === "discord") {
                handleDiscordCode(code as string);
            }
            if (state != null) handleOauth2TwitterCode(state, code)
        }

        if (oauth_token !== null && oauth_token !== undefined && oauth_verifier !== null && oauth_verifier !== undefined) {
            delete windowLocationSearch.oauth_token;
            delete windowLocationSearch.oauth_verifier;
            handleOauth1Twitter(oauth_token as string, oauth_verifier as string)
        }

        const newQueryString = queryString.stringify(windowLocationSearch)
        window.history.replaceState({}, document.title, `${window.location.pathname}${newQueryString.length > 0 ? `?${newQueryString}` : ""}`);

    }, [location])

    async function handleOauth1Twitter(oauth_token: string, oauth_verifier: string) {
        localStorage.setItem("twitterOauth1Data", JSON.stringify({ oauth_token, oauth_verifier }));
        window.close()
    }

    async function handleOauth2TwitterCode(state: any, code: any) {
        localStorage.setItem("twitterOauth2Data", JSON.stringify({ state, code }));
        window.close()
    }

    async function handleDiscordCode(code: string) {
        console.warn(code)

    }

    return (
        <div id="main-handler" className="d-flex flex-column">
            <Switch>
                <Redirect exact from='/' to='/home' />
                <Route path="/home" children={<Home musicInfo={musicInfo} setMusicInfo={setMusicInfo} />}></Route>
                {/* <Route path="/whitelist" children={<MintContainer musicInfo={musicInfo} setMusicInfo={setMusicInfo} />}></Route> */}
                <Route path="/brokerage" children={<Brokerage locationPaths={locationPaths} />}></Route>
                <Route path="/art-content" children={<ArtContent />}></Route>
                {/* <Route path="/build" children={<BuildWolf />}></Route> */}

            </Switch>

            <ToastContainer
                position="bottom-center"
                transition={Zoom}
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <ReactPlayer url='https://www.youtube.com/watch?v=9Ri7TcukAJ8' playing={isMusicPlaying} />
            <h1>Is Playing: {isMusicPlaying.toString()}</h1> */}
        </div>
    )
}

export default MainHandler