import api, { API_URL } from "../api"
import { delay } from "../../utils"

async function getBrokerageUserInfo() {
    return await api.helpers.get(`${API_URL}/brokerage/brokerage-user-info`, null, null, "brokerageUserInfo");
}

async function tweetDailyGm(tweetUserCustomText = "GM Test") {
    const data = JSON.stringify({ tweetUserCustomText });
    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return await api.helpers.post(`${API_URL}/brokerage/say-gm`, data, customConfig, null);
}

async function analyzeUserTweets() {
    const data = undefined;
    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return await api.helpers.post(`${API_URL}/brokerage/check-content-usage`, data, customConfig, null);
}

export default {
    getBrokerageUserInfo,
    tweetDailyGm,
    analyzeUserTweets,
}