import "./Navbar.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useLocation } from 'react-router-dom';

import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faBars } from "@fortawesome/free-solid-svg-icons"

import Offcanvas from 'react-bootstrap/Offcanvas';

import logo from "../Images/Icons/logo.png"
import { ReactComponent as MenuToggleIcon } from "../Images/Icons/MenuToggle.svg"

import { ALPHA_CLUB_URL, MOBILE_MAX_WIDTH } from "../utils/constants"

const navigationLimitWidth = 1175;

const Navbar: FC<any> = (props) => {
    const { className, transparent, fillOnScrollUp } = props

    const location = useLocation()

    const [showOverlay, setShowOverlay] = useState(false)
    const [currentPage, setCurrentPage] = useState<string | null>(null)

    useEffect(() => {
        console.log(location.pathname)

        const paths = location.pathname.slice(1, location.pathname.length).split("/")
        const basePath: string = paths[0]
        setCurrentPage(basePath)

    }, [location.pathname])

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        animateNavbarOnScroll()
    }, [])

    function animateNavbarOnScroll() {
        // const showAnim = gsap.from('#navbar', {
        //     yPercent: -100,
        //     paused: true,
        //     duration: 0.2
        // }).progress(1);
        const showAnim = gsap.timeline({ duration: 0.2, paused: true });
        showAnim.from("#navbar #navbar-pages-links", { height: 0, opacity: 0 }).progress(1);

        ScrollTrigger.create({
            start: "top top",
            end: 99999,
            onUpdate: (self) => {
                self.direction === -1 ? showAnim.play() : showAnim.reverse()
            }
        });

        if (transparent && fillOnScrollUp) gsap.to("#navbar", {
            background: "rgba(13, 13, 15, 1)", duration: 0.2, scrollTrigger: {
                trigger: "body",
                start: "top top",
                end: "+=400px",
                scrub: true,
            }
        });
    }

    return (
        <div id="navbar" className={`d-flex justify-content-start align-items-center pt-0 ${className} ${transparent ? "transparent" : ""}`}>
            <div id="navbar-offcanvas-toggle" className="col-1 d-flex align-items-center ps-1 px-lg-2 px-xl-3">
                <button id="menu-toggle-button" className="btn-simple d-flex hover-gold mx-lg-2 p-auto" onClick={() => setShowOverlay(true)}>
                    <MenuToggleIcon style={{ width: "100%", height: "100%" }} />
                </button>
            </div>
            <div className="d-flex flex-column justify-content-start align-items-center text-center col-10 pt-md-3">
                <a href="/" className="navbar-option text-no-decoration d-flex justify-content-center align-items-center w-fit">
                    <h4 className="text-luxury text-white mb-0 ms-2">Westmont Capital</h4>
                </a>
                <div id="navbar-pages-links" className="d-none d-md-flex justify-content-center align-items-center w-100 my-1" style={{ height: "35px" }}>
                    <a href="/brokerage" className={`navbar-option text-no-decoration text-white d-flex justify-content-end mx-2 px-3 ${currentPage === "brokerage" ? "selected" : ""}`} style={{ width: "170px", height: "35px" }}><h6 className="text-luxury my-auto">Brokerage</h6></a>
                    <a href={ALPHA_CLUB_URL} className={`navbar-option text-no-decoration text-white d-flex justify-content-center mx-2 px-3 ${currentPage === "alpha-club" ? "selected" : ""}`} style={{ width: "170px", height: "35px" }}><h6 className="text-luxury my-auto">Alpha Club</h6></a>
                    <a href="/art-content" className={`navbar-option text-no-decoration text-white d-flex justify-content-end mx-2 px-3 ${currentPage === "art-content" ? "selected" : ""}`} style={{ width: "170px", height: "35px" }}><h6 className="text-luxury my-auto">Art Content</h6></a>
                    {/* <span className={`navbar-option text-no-decoration text-white d-flex mx-2 px-3`} style={{ width: "170px", height: "35px", opacity: 0.5 }}><h6 className="text-luxury my-auto">Build Wolf</h6></span> */}
                    {/* <a href="/build" className={`navbar-option text-no-decoration text-white d-flex mx-2 px-3 ${currentPage === "build" ? "selected" : ""}`} style={{ width: "170px", height: "35px" }}><h6 className="text-luxury my-auto">Build Wolf</h6></a> */}
                </div>
            </div>
            <div className="col-1 d-flex align-items-center justify-content-end navbar-socials-container px-3">
                <a href="https://twitter.com/WolfofEtherSt" target="_blank" rel="noopener noreferrer" className="navbar-option text-no-decoration text-white hover-gold d-flex bg-first mx-2 p-auto" style={{ width: "60px", height: "60px" }}><FontAwesomeIcon icon={faTwitter} size="2x" className="m-auto" /></a>
            </div>

            <Offcanvas id="navbar-overlay" show={showOverlay} onHide={() => setShowOverlay(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="d-flex align-items-center w-100">
                        <a href="/" className="text-no-decoration d-flex align-items-center">
                            <img src={logo} className="logo-img" />
                            <h4 className="text-luxury text-white text-bold mb-0 ms-2">Westmont Capital</h4>
                        </a>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="d-flex flex-column justify-content-between">
                    <div className="d-flex flex-column">
                        <a href="/brokerage" className={`navbar-option text-no-decoration text-white d-flex bg-first mx-2 px-3 ${currentPage === "brokerage" ? "selected" : ""}`} style={{ width: "auto", height: "60px" }}><h4 className="text-luxury my-auto">Brokerage</h4></a>
                        <a href={ALPHA_CLUB_URL} className={`navbar-option text-no-decoration text-white d-flex bg-first mx-2 px-3 ${currentPage === "alpha-club" ? "selected" : ""}`} style={{ width: "auto", height: "60px" }}><h4 className="text-luxury my-auto">Alpha Club</h4></a>
                        <a href="/art-content" className={`navbar-option text-no-decoration text-white d-flex bg-first mx-2 px-3 ${currentPage === "art-content" ? "selected" : ""}`} style={{ width: "auto", height: "60px" }}><h4 className="text-luxury my-auto">Art Content</h4></a>
                        {/* <a href="/build" className={`navbar-option text-no-decoration text-white d-flex bg-first mx-2 px-3 ${currentPage === "build" ? "selected" : ""}`} style={{ width: "auto", height: "60px" }}><h4 className="text-luxury my-auto">Build</h4></a> */}
                    </div>
                    <div className="d-flex justify-content-around w-100">
                        <button disabled className="btn-simple d-flex bg-first mx-2 p-auto" style={{ width: "60px", height: "60px", color: "grey" }}><FontAwesomeIcon icon={faDiscord} size="2x" className="m-auto" /></button>
                        <a href="https://twitter.com/WolfofEtherSt" target="_blank" rel="noopener noreferrer" className="text-no-decoration text-white hover-transparent d-flex bg-first mx-2 p-auto" style={{ width: "60px", height: "60px" }}><FontAwesomeIcon icon={faTwitter} size="2x" className="m-auto" /></a>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default Navbar;
