import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyBYjxl8Fr75_xfZb2gFG-K_4CxzyshcXvo",
    authDomain: "wolves-capital.firebaseapp.com",
    projectId: "wolves-capital",
    storageBucket: "wolves-capital.appspot.com",
    messagingSenderId: "391407960023",
    appId: "1:391407960023:web:f35388e6a54fc547931592",
    measurementId: "G-462LWDV2LC"
};

export const app = initializeApp(firebaseConfig);
