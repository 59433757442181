import { createContext, useContext } from "react";

interface TwitterOauth2PopupContextState {
    oauthSetupResult: { success: boolean, error?: string } | undefined,
    openPopup: Function,
}

const DEFAULT_CONTEXT: TwitterOauth2PopupContextState = {
    oauthSetupResult: undefined,
    openPopup: () => null,
}

export const TwitterOauth2PopupContext = createContext<TwitterOauth2PopupContextState>(DEFAULT_CONTEXT)

export function useTwitterOauth2Popup(): TwitterOauth2PopupContextState {
    return useContext(TwitterOauth2PopupContext);
}
