import { type ReactNode, useEffect, useState, createContext, useContext } from "react";
import axios from "axios"
import mixpanel from "mixpanel-browser";

import api from "../../utils/api/api";
import { app } from "../../utils/firebaseApp"
import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";

import { UserAccount } from "../../utils/types";
import { UserContext, BrokerageUserInfoFetch } from "./useUser"

const auth = getAuth(app);
const provider = new TwitterAuthProvider();

export function UserProvider(
    { children }: any
) {
    const [loggingIn, setLoggingIn] = useState(false)
    const [currentUser, setCurrentUser] = useState<UserAccount | null>(null)
    const [currentUserBrokerageInfoFetch, setCurrentUserBrokerageInfoFetch] = useState<BrokerageUserInfoFetch>({ fetching: false, currentUserBrokerageInfo: null, fetch: fetchCurrentUserBrokerageInfo })
    // const { address, isConnected } = useAccount()
    // const { chain } = useNetwork()
    // const { signMessageAsync } = useSignMessage()

    // useEffect(() => {
    //     tryReloginWithToken()
    // }, [])

    useEffect(() => {
        console.log("Current User: ", currentUser)
        setCurrentUserBrokerageInfoFetch({ ...currentUserBrokerageInfoFetch, fetch: fetchCurrentUserBrokerageInfo })
    }, [currentUser])

    // const login = async () => {
    //     if (loggingIn || window.ethereum === undefined || !isConnected || address === undefined) return false;

    //     setLoggingIn(true)
    //     setCurrentBroker(null)

    //     const signatureMessageInfo = await api.brokers.getUserMessageToSign(address);
    //     if (signatureMessageInfo === null) {
    //         setLoggingIn(false)
    //         return false;
    //     }
    //     let loginResponse;
    //     try {
    //         loginResponse = await signLoginMessage(address, signatureMessageInfo.message)
    //     } catch (err) {
    //         console.error(err)
    //         setLoggingIn(false)
    //         return false;
    //     }

    //     if (loginResponse === null || !loginResponse.success) {
    //         setLoggingIn(false)
    //         return false;
    //     }
    //     setCurrentBroker(loginResponse.userBrokerAccount);
    //     setLoggingIn(false)
    //     localStorage.setItem('token', loginResponse.token)
    //     return true;
    // }

    const login = async () => {
        if (loggingIn) return false;

        setLoggingIn(true)
        setCurrentUser(null)
        setCurrentUserBrokerageInfoFetch({ ...currentUserBrokerageInfoFetch, currentUserBrokerageInfo: null })

        signInWithPopup(auth, provider)
            .then(async (result) => {
                // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
                // You can use these server side with your app's credentials to access the Twitter API.
                const credential = TwitterAuthProvider.credentialFromResult(result);
                // if (credential === null) return;
                const token = credential?.accessToken;
                const secret = credential?.secret;
                if (token === undefined || secret === undefined) {
                    setLoggingIn(false)
                    return false;
                }

                const loginResponse = await api.users.connectWithTwitter(token, secret)
                setLoggingIn(false)

                if (loginResponse === null || !loginResponse.success) {
                    return false;
                }
                console.log(loginResponse.userAccount)
                setCurrentUser(loginResponse.userAccount);
                // setCurrentUserBrokerageInfo(await api.brokerage.getBrokerageUserInfo());
                localStorage.setItem('token', loginResponse.token)
                mixpanel.track("Login", {
                    withToken: false
                })
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode, errorMessage);

                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = TwitterAuthProvider.credentialFromError(error);

                setLoggingIn(false)
            });
    }

    const tryReloginWithToken = async () => {
        const brokerToken = localStorage.getItem("token");
        if (brokerToken !== null) {
            setLoggingIn(true)
            console.log("Relogging with token")
            const userAccount = await api.users.fetchConnectedUserAccount(brokerToken);
            console.log(userAccount)
            setCurrentUser(userAccount)
            // setCurrentUserBrokerageInfo(await api.brokerage.getBrokerageUserInfo());
            setLoggingIn(false)
            mixpanel.track("Login", {
                withToken: true
            })
            return true;
        }
    }

    const logout = () => {
        setCurrentUser(null)
        setCurrentUserBrokerageInfoFetch({ ...currentUserBrokerageInfoFetch, currentUserBrokerageInfo: null })
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['authorization'];
        mixpanel.track("Logout")
    }

    // const signLoginMessage = async (
    //     address: `0x${string}`,
    //     message: string
    // ) => {
    //     const ethWindow: any = window.ethereum;
    //     const provider = new ethers.providers.Web3Provider(ethWindow)
    //     const signer = provider.getSigner();
    //     const signature = await signer.signMessage(message);
    //     return await api.brokers.loginBrokerAccount(address, signature);
    // }
    const getCurrentUser = () => currentUser;

    async function fetchCurrentUserBrokerageInfo() {
        if (getCurrentUser() === null || !getCurrentUser()?.isMember) return;
        setCurrentUserBrokerageInfoFetch({ ...currentUserBrokerageInfoFetch, fetching: true });
        const resultPromise = api.brokerage.getBrokerageUserInfo();
        resultPromise.then(result => setCurrentUserBrokerageInfoFetch({ ...currentUserBrokerageInfoFetch, fetching: false, currentUserBrokerageInfo: result }))
        return resultPromise;
    }

    return (
        <UserContext.Provider
            value={{
                loggingIn,
                currentUser,
                setCurrentUser,
                currentUserBrokerageInfoFetch,
                setCurrentUserBrokerageInfoFetch,
                login,
                tryReloginWithToken,
                logout
            }}
        >
            {children}
        </UserContext.Provider>
    );
}