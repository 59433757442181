import './App.css';
import './BootstrapOverride.css';
import './ToastifyOverride.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";

import MainHandler from './MainHandler.tsx'
 
import { UserProvider } from './Components/Connection/UserProvider';

function App() {
  
  return(
      <UserProvider>
        <div className="App">
          <MainHandler />
        </div>
      </UserProvider>
  );
}

export default App;
