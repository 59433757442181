import "./BrokerageAccount.css"
import React, { FC, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import gsap from "gsap";

import { ReactComponent as ChevronDown } from "../../Images/Icons/ChevronDown.svg";
import loadingIconAnim from "../../Images/Icons/loadingIcon.gif";
import brokerageLifestyleVideo from "../../Videos/brokerageLifestyle.mp4"

import LoadingUserContainer from "./LoadingUserContainer";
import InviteBrokerModal from "./Invites/InviteBrokerModal"
import BrokerageAccountProfile from "./Profile/BrokerageAccountProfile";
import BrokerageAccountInvites from "./Invites/BrokerageAccountInvites";
import BrokerageAccountPoints from "./Points/BrokerageAccountPoints";

import { useUser } from "../../Components/Connection/useUser";
import { vh } from "../../utils/utils";
import { UserAccount, TreeNode } from "../../utils/types"
import api from "../../utils/api/api"

enum DashboardPage {
    PROFILE = "profile",
    INVITES = "invites",
    POINTS = "points",
    REWARDS = "rewards",
    LEADERBOARDS = "leaderboards",
}

interface BrokerFamilyTree {
    loading: boolean;
    brokerParent: UserAccount | null;
    brokerChildren: UserAccount[] | null
    brokerFamilyTree: TreeNode | null;
}

const BrokerageAccount: FC<any> = (props) => {
    const { locationPaths } = props;

    const history = useHistory();
    const { currentUser, loggingIn } = useUser();

    const [currentDashboardPage, setCurrentDashboardPage] = useState(DashboardPage.PROFILE);
    const [showInviteBrokerModal, setShowInviteBrokerModal] = useState(false)

    const [brokerFamilyTreeInfo, setBrokerFamilyTreeInfo] = useState<BrokerFamilyTree>({ loading: false, brokerParent: null, brokerChildren: null, brokerFamilyTree: null })

    useEffect(() => {
        if (currentUser === null || !currentUser.isMember) return;

        if (locationPaths[2] === DashboardPage.PROFILE.valueOf()) setCurrentDashboardPage(DashboardPage.PROFILE);
        else if (locationPaths[2] === DashboardPage.INVITES.valueOf()) setCurrentDashboardPage(DashboardPage.INVITES);
        else if (locationPaths[2] === DashboardPage.POINTS.valueOf()) setCurrentDashboardPage(DashboardPage.POINTS);
        else if (locationPaths[2] === DashboardPage.REWARDS.valueOf()) setCurrentDashboardPage(DashboardPage.REWARDS);
        else if (locationPaths[2] === DashboardPage.LEADERBOARDS.valueOf()) setCurrentDashboardPage(DashboardPage.LEADERBOARDS);
        else {
            history.push("/brokerage/account/profile");
            setCurrentDashboardPage(DashboardPage.PROFILE);
        }
    }, [currentUser, locationPaths])

    useEffect(() => {
        setTimeout(animateWelcomeContainer, 1000)
    }, [])

    function animateWelcomeContainer() {
        const welcomeContainerTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: "#brokerage-account",
                start: "top top",
                end: `top+=${vh(100)} top`,
                pin: "#brokerage-account-welcome",
                scrub: true,
            }
        });
        welcomeContainerTimeline.to("#brokerage-account-welcome-container", { opacity: 0 });
        welcomeContainerTimeline.to("#brokerage-account-presentation-container", { opacity: 0 }, "<");
    }

    function switchDashboardPage(page: DashboardPage) {
        setCurrentDashboardPage(page)
        history.push(`/brokerage/account/${page.valueOf()}`);
    }


    useEffect(() => {
        if (brokerFamilyTreeInfo.brokerFamilyTree === null) getBrokerDirectFamily()
    }, [currentUser, brokerFamilyTreeInfo.brokerFamilyTree])

    async function getBrokerDirectFamily() {
        if (currentUser === null) return;

        setBrokerFamilyTreeInfo({ ...brokerFamilyTreeInfo, loading: true })

        const brokerParentId = currentUser.userBrokerInfo.parentBrokerId;
        const childrenBrokersIds = currentUser.userBrokerInfo.invitedBrokersIds;
        const familyIds = brokerParentId !== null ? [brokerParentId, ...childrenBrokersIds] : childrenBrokersIds.length ? childrenBrokersIds : null;
        // const familyIdsBatches = familyIds.reduce((batch: string[][], code: string | null) => { if (code === null) return batch; batch[batch.length - 1].length >= MAX_QUERY_SIZE ? batch.push([code]) : batch[batch.length - 1].push(code); return batch }, [[]])

        // const allResults = await Promise.all(familyIdsBatches.map(async (batch) => {
        //     const promise = api.brokers.fetchPremintBrokerAccount(null, batch);
        //     await delay(200);
        //     return promise;
        // }))
        if (familyIds === null) {
            setBrokerFamilyTreeInfo({ ...brokerFamilyTreeInfo, loading: false })
            return;
        }

        const fetchedBrokers = await api.users.fetchUserAccount(null, familyIds);
        console.log(fetchedBrokers)
        if (fetchedBrokers === null) return;

        let brokerParent: UserAccount | null = null;
        const brokerChildren: UserAccount[] = [];
        if (fetchedBrokers instanceof Array) {
            for (const broker of fetchedBrokers) {
                if (broker.userId === brokerParentId) brokerParent = broker;
                else brokerChildren.push(broker);
            }
        } else {
            if (fetchedBrokers.userId === brokerParentId) brokerParent = fetchedBrokers;
            else brokerChildren.push(fetchedBrokers);
        }

        // for (const batchResult of allResults) {
        //     if (batchResult === null) continue;
        //     if (batchResult instanceof Array) {
        //         for (const batchResultBroker of batchResult) {
        //             if (batchResultBroker.userId === brokerParentId) { setbrokerParent(batchResultBroker); continue; }
        //             brokerChildren.push(batchResultBroker);
        //         }
        //         continue;
        //     }
        //     if (batchResult.userId === brokerParentId) { setbrokerParent(batchResult); continue; }
        //     brokerChildren.push(batchResult);
        // }

        setBrokerFamilyTreeInfo({ ...brokerFamilyTreeInfo, brokerParent, brokerChildren });
    }

    function createBrokerTree(parentBroker: UserAccount | null, broker: UserAccount, childrenBrokers: UserAccount[] | null) {
        const brokerDescendance = {
            root: broker,
            children: childrenBrokers === null ? [] : childrenBrokers.map(child => {
                return {
                    root: child,
                    children: []
                }
            })
        }
        return parentBroker !== null ? {
            root: parentBroker,
            children: [
                brokerDescendance
            ]
        } : brokerDescendance;
    }

    useEffect(() => {
        if (currentUser === null || brokerFamilyTreeInfo.brokerParent === null && brokerFamilyTreeInfo.brokerChildren === null) return;
        const newBrokerFamilyTree = createBrokerTree(brokerFamilyTreeInfo.brokerParent, currentUser, brokerFamilyTreeInfo.brokerChildren);
        console.log(newBrokerFamilyTree)
        setBrokerFamilyTreeInfo({ ...brokerFamilyTreeInfo, loading: false, brokerFamilyTree: newBrokerFamilyTree })
    }, [brokerFamilyTreeInfo.brokerParent, brokerFamilyTreeInfo.brokerChildren])

    return (
        <>
            <LoadingUserContainer />
            {!loggingIn &&
                <div id="brokerage-account" className="d-flex flex-column align-items-center">
                    <div id="brokerage-account-welcome" className="d-flex flex-column align-items-center">
                        <video id="brokerage-account-video" src={brokerageLifestyleVideo} width="auto" height="auto" muted={true} autoPlay={true} loop={true} playsInline={true} />
                        <div id="brokerage-account-welcome-container" className="d-flex flex-column">
                            <h1 className="text-normal text-center">{currentUser?.userInfo.username},<br />welcome to Westmont Capital Brokerage.</h1>
                        </div>
                        <div id="brokerage-account-presentation-container" className="d-flex flex-column align-items-center col-11 col-sm-10 col-md-8 col-lg-6 col-xl-4">
                            <h4 className="text-normal text-center mb-5">This is your personal member space. Manage your account, invite new members, earn and view your points, claim your rewards and analyze the brokerage leaderboards.</h4>
                            {/* <ChevronDown width="85px" height="auto" /> */}
                        </div>
                    </div>

                    <div id="brokerage-account-dashboard" className="d-flex flex-column text-white">
                        <div id="brokerage-account-dashboard-nav" className="d-flex flex-wrap justify-content-center text-normal-base-size my-5">
                            <button className="btn-simple mx-4" onClick={() => switchDashboardPage(DashboardPage.PROFILE)}><h3 className={`mb-0 ${currentDashboardPage === DashboardPage.PROFILE ? "text-underline" : ""}`}>Profile</h3></button>
                            <button className="btn-simple mx-4" onClick={() => switchDashboardPage(DashboardPage.INVITES)}><h3 className={`mb-0 ${currentDashboardPage === DashboardPage.INVITES ? "text-underline" : ""}`}>Invites</h3></button>
                            <button className="btn-simple mx-4" onClick={() => switchDashboardPage(DashboardPage.POINTS)}><h3 className={`mb-0 ${currentDashboardPage === DashboardPage.POINTS ? "text-underline" : ""}`}>Points</h3></button>
                            <button className="btn-simple mx-4" onClick={() => switchDashboardPage(DashboardPage.REWARDS)} disabled style={{ opacity: .3 }}><h3 className={`mb-0 ${currentDashboardPage === DashboardPage.REWARDS ? "text-underline" : ""}`}>Rewards</h3></button>
                            <button className="btn-simple mx-4" onClick={() => switchDashboardPage(DashboardPage.LEADERBOARDS)} disabled style={{ opacity: .3 }}><h3 className={`mb-0 ${currentDashboardPage === DashboardPage.LEADERBOARDS ? "text-underline" : ""}`}>Leaderboards</h3></button>
                            <button className="btn-simple mx-4" onClick={() => switchDashboardPage(DashboardPage.LEADERBOARDS)} disabled style={{ opacity: .3 }}><h3 className={`mb-0 ${currentDashboardPage === DashboardPage.LEADERBOARDS ? "text-underline" : ""}`}>Events</h3></button>
                        </div>

                        {currentDashboardPage === DashboardPage.PROFILE ? <BrokerageAccountProfile setShowInviteBrokerModal={setShowInviteBrokerModal} /> :
                            currentDashboardPage === DashboardPage.INVITES ? <BrokerageAccountInvites setShowInviteBrokerModal={setShowInviteBrokerModal} brokerFamilyTreeInfo={brokerFamilyTreeInfo} /> :
                                currentDashboardPage === DashboardPage.POINTS ? <BrokerageAccountPoints /> :
                                    null}
                    </div>

                    <InviteBrokerModal show={showInviteBrokerModal} onHide={() => setShowInviteBrokerModal(false)} />
                </div>}
        </>
    )
}

export default BrokerageAccount;