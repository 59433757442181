import { gsap } from "gsap";

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const capitalize = (string: string, separator = " ") => string.split(separator).map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

export function shortenAddress(address: string, borderLengths: number = 5): string {
    return address.slice(0, borderLengths) + "..." + address.slice(-borderLengths, address.length)
}

const stringToUrlEncoding = {
    " ": "%20",
    "#": "%23",
    "@": "%40",
    "\n": "%0A",
    ":": "%3A",
}
export const formatStringForUrl = (str: string) => {
    Object.entries(stringToUrlEncoding).map(encoding => str = str.replaceAll(encoding[0], encoding[1]))
    return str;
    //stringToUrlEncoding.map() //str.replaceAll(" ", "%20").replaceAll("#", "%23").replaceAll("@", "%40").replaceAll("\n", "%0A").replaceAll(":", "%3A")
}

export function timestampToSmallDate(timestamp: number) {
    var a = new Date(timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    // var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    const time = `${month} ${date}`
    return time;
}
export function timestampToLongDate(timestamp: number) {
    var a = new Date(timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    // const time = `${month} ${date}`
    return time;
}

export function timestampToRelative(msTimestamp: number, replaceSmallGap = false): string {
    const currentTimestamp = Date.now();

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = currentTimestamp - msTimestamp;

    if (replaceSmallGap && Math.abs(elapsed) < msPerMinute) return "now";

    if (elapsed < msPerMinute) {
        const result = Math.floor(elapsed / 1000);
        return result + ` second${result > 1 ? "s" : ""} ago`;
    }

    else if (elapsed < msPerHour) {
        const result = Math.floor(elapsed / msPerMinute);
        return result + ` minute${result > 1 ? "s" : ""} ago`;
    }

    else if (elapsed < msPerDay) {
        const result = Math.floor(elapsed / msPerHour);
        return result + ` hour${result > 1 ? "s" : ""} ago`;
    }

    else if (elapsed < msPerMonth) {
        const result = Math.floor(elapsed / msPerDay);
        return result + ` day${result > 1 ? "s" : ""} ago`;
    }

    else if (elapsed < msPerYear) {
        const result = Math.floor(elapsed / msPerMonth);
        return result + ` month${result > 1 ? "s" : ""} ago`;
    }

    else {
        const result = Math.floor(elapsed / msPerYear);
        return result + ` year${result > 1 ? "s" : ""} ago`;
    }
}

export function getTimeDifference(current: number, next: number): { days: number, hours: number, minutes: number, seconds: number } {
    var delta = Math.abs(next - current) / 1000;

    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    var seconds = Math.floor(delta % 60);

    return { days, hours, minutes, seconds };
}
export function getTimeDifferenceFromDelta(delta: number): { days: number, hours: number, minutes: number, seconds: number } {
    delta = Math.abs(delta) / 1000;

    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    var seconds = Math.floor(delta % 60);

    return { days, hours, minutes, seconds };
}

export function transformBucketTimeDifferenceToString(bucketTimeDifference: { days: number, hours: number, minutes: number, seconds: number }): string {
    const daysString = bucketTimeDifference.days > 0 ? `${bucketTimeDifference.days} day${bucketTimeDifference.days === 1 ? "" : "s"} ` : "";
    const hoursString = bucketTimeDifference.days > 0 || bucketTimeDifference.hours > 0 ? `${bucketTimeDifference.hours} hour${bucketTimeDifference.hours === 1 ? "" : "s"} ` : "";
    const minutesString = bucketTimeDifference.days > 0 || bucketTimeDifference.hours > 0 || bucketTimeDifference.minutes > 0 ? `${bucketTimeDifference.minutes} minute${bucketTimeDifference.minutes === 1 ? "" : "s"} ` : "";
    const secondsString = `${bucketTimeDifference.seconds} second${bucketTimeDifference.seconds === 1 ? "" : "s"}`;

    return daysString + hoursString + minutesString + secondsString;
}

export function transformBucketTimeDifferenceToClock(bucketTimeDifference: { days: number, hours: number, minutes: number, seconds: number }, withNullFiller: boolean): string {
    const daysString = bucketTimeDifference.days > 0 ? `${bucketTimeDifference.days > 9 ? "" : "0"}${bucketTimeDifference.days}:` : withNullFiller ? "00:" : "";
    const hoursString = bucketTimeDifference.days > 0 || bucketTimeDifference.hours > 0 ? `${bucketTimeDifference.hours > 9 ? "" : "0"}${bucketTimeDifference.hours}:` : withNullFiller ? "00:" : "";
    const minutesString = bucketTimeDifference.days > 0 || bucketTimeDifference.hours > 0 || bucketTimeDifference.minutes > 0 ? `${bucketTimeDifference.minutes > 9 ? "" : "0"}${bucketTimeDifference.minutes}:` : withNullFiller ? "00:" : "";
    const secondsString = bucketTimeDifference.seconds > 9 ? bucketTimeDifference.seconds.toString() : `0${bucketTimeDifference.seconds}`;

    return daysString + hoursString + minutesString + secondsString;
}


export async function animateLoadingSquares(containerId: string, promise: Promise<any>) {
    const fadeOutDuration = 1.5;
    const fadeInDuration = 1.5;

    const fadeOutValue = 0.3;
    const fadeInValue = 0.85;

    let loading = true;
    promise.then(() => loading = false)

    while (loading) {
        gsap.fromTo(`#${containerId} .loading-square`, { opacity: fadeInValue }, { opacity: fadeOutValue, duration: fadeOutDuration })
        await delay(fadeOutDuration * 1000)
        gsap.fromTo(`#${containerId} .loading-square`, { opacity: fadeOutValue }, { opacity: fadeInValue, duration: fadeInDuration })
        await delay(fadeInDuration * 1000)
    }
}

export const vh = (coef: number) => window.innerHeight * (coef / 100);
export const vw = (coef: number) => window.innerWidth * (coef / 100);

export function getFullBaseUrl(window: Window, encode = false) {
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port.length > 0 ? ":" + window.location.port : ""}`
    return encode ? encodeURIComponent(baseUrl) : baseUrl;
}

export function numberWithCommas(num: number | string) {
    if (typeof num !== "number" && typeof num !== "string") return num;
    var parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export function getRoundedPrice(price: number): string {
    if (typeof price !== "number" && typeof price !== "string") return price;

    let stringPrice = price.toString();
    if (stringPrice.includes("e-")) {
        const power = stringPrice.substring(stringPrice.indexOf("e"))
        return Number(stringPrice.substring(0, stringPrice.indexOf(power))).toFixed(2) + power;
    }
    let decimalPart = stringPrice.split('.')[1]?.substring(0, 9);
    if (decimalPart === undefined) return stringPrice;

    const nonZeroDecimalPart = Number(decimalPart).toString();
    return (price + Number.EPSILON).toFixed(price > 0.1 ? 2 : (3 + decimalPart.length - nonZeroDecimalPart.length));
    // return Math.round((price + Number.EPSILON) * 100) / 100;
}

export function padNumber(number: number, padding: string, finalLength: number): string {
    let numberString = number.toString();
    while (numberString.length < finalLength) {
        numberString = padding + numberString;
    }
    return numberString;
}
