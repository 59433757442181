import "./AddressClicker.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import PopoverContainer, { PopoverTriggerType } from '@idui/react-popover'


const AddressClicker: FC<any> = (props) => {
    const { address, className, component }: { address: string, className: string, component: any } = props;

    const [clicked, setClicked] = useState(false)

    useEffect(() => {
        console.log(`Clicked: ${clicked}`)
    }, [clicked])

    const copyAddress = () => {
        setClicked(true)
        if (navigator.clipboard !== undefined) navigator.clipboard.writeText(address);
        setTimeout(() => setClicked(false), 1000)
    }

    return (
        <PopoverContainer className="address-clicker-popover" content={clicked ? "Copied" : "Copy"} trigger={"hover" as PopoverTriggerType} animation={{ animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } }, initial: { opacity: 0, scale: 0.9 } }} closeOnEnter fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck() { }} onFocus={function noRefCheck() { }} withArrow={false} >
            <span className={`address-clicker ${className}`} onClick={copyAddress}>
                {component}
            </span>
        </PopoverContainer>
    )
}

export default AddressClicker;