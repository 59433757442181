import "./ArtContentMedia.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { ReactComponent as DownloadIcon } from "../../Images/Icons/Download.svg"

const ArtContentMedia: FC<any> = (props) => {
    const { media, mediaType, containerStyle, containerClass, mediaFilePath, extraComponents } = props

    const mediaVideoRef = useRef<HTMLVideoElement>(null);

    const [isHovering, setIsHovering] = useState(false)
    const [mediaIsLoaded, setMediaIsLoaded] = useState(false)

    // useEffect(() => {
    //     if (mediaVideoRef.current === null) return;
    //     if (isHovering) {
    //         mediaVideoRef.current.play();
    //     } else {
    //         mediaVideoRef.current.pause();
    //     }
    // }, [isHovering]);

    return (
        <div className={`art-content-media ${containerClass}`} style={{ height: "fit-content", ...containerStyle }} onMouseEnter={(e) => setIsHovering(true)} onMouseLeave={(e) => setIsHovering(false)}>
            {mediaType === "image" ? <LazyLoadImage src={media} style={{ width: "100%", height: "auto" }} effect="blur" afterLoad={() => setMediaIsLoaded(true)} placeholder={<div style={{ width: "100%", height: "300px" }}></div>} /> :
                mediaType == "video" ? <video ref={mediaVideoRef} src={media} muted={true} autoPlay={true} loop={true} playsInline={true} onPlay={() => setMediaIsLoaded(true)} /> :
                    null
            }
            {mediaIsLoaded && <a href={`/ArtContent/${mediaFilePath}`} download className="art-content-media-download-btn btn-simple text-white"><DownloadIcon /></a>}
            {mediaIsLoaded && extraComponents !== undefined ? extraComponents.map((component: any) => component) : null}
        </div>
    )
}

export default ArtContentMedia;
