export const MOBILE_MAX_WIDTH = 850;

export const FRONTEND_URL = "https://westmontcapital.xyz/";
export const ALPHA_CLUB_URL = "https://alpha.westmontcapital.xyz/big-board";

export const INVITES_CONFIG_PER_POINTS = [
    { minPoints: 0, timespan: 604800000 * 4, maxInvites: 0 },
    { minPoints: 1000, timespan: 604800000 * 4, maxInvites: 1 },
    { minPoints: 1500, timespan: 604800000 * 2, maxInvites: 1 },
    { minPoints: 3000, timespan: 604800000, maxInvites: 1 },
    { minPoints: 7500, timespan: 604800000, maxInvites: 2 },
];

export const CONTENT_USAGE_ANALYSIS = {
    MINIMUM_TIMESPAN_BETWEEN_ANALYSIS: 86_400_000,
};
