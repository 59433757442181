import axios from "axios";

import { TreeNode, UserAccount } from "../../types"
import { delay } from "../../utils"
import api, { API_URL } from "../api"

// async function fetchBrokerTree(
//     brokerName: string
// ): Promise<TreeNode | null> {
//     const response = await axios.get(`${API_URL}/brokers/getBrokerTree?brokerName=${brokerName}`);
//     const responseData = response.data;
//     if (!responseData.success) {
//         console.error(responseData.error);
//         return null
//     }

//     // await delay(30000)
//     return responseData.referralTree === null ? null : responseData.referralTree.tree;
// }

// async function getUserMessageToSign(
//     walletAddress: string
// ): Promise<{ message: string, nonce: string } | null> {
//     const data = JSON.stringify({ walletAddress });
//     const customConfig = {
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     };

//     let response;
//     try {
//         response = await axios.post(`${API_URL}/users/login/getSignatureMessage`, data, customConfig);
//     } catch (error) {
//         console.error(error);
//         return null
//     }

//     const responseData = response.data;
//     if (!responseData.success) {
//         console.error(responseData.error);
//         return null;
//     }

//     return responseData.signatureMessageInfo;
// }

// async function loginBrokerAccountWithWallet(
//     walletPubkey: string,
//     signature: string
// ): Promise<{ userBrokerAccount: UserAccount, success: boolean, token: string } | null> {
//     const data = JSON.stringify({ walletPubkey, signature });
//     const customConfig = {
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     };

//     let response;
//     try {
//         response = await axios.post(`${API_URL}/users/login/verifySignature`, data, customConfig);
//     } catch (error) {
//         console.error(error);
//         return null
//     }

//     const responseData = response.data;
//     if (!responseData.success) {
//         console.error(responseData.error);
//         return null;
//     }

//     axios.defaults.headers.common['authorization'] = "Bearer " + responseData.token;

//     return responseData;
// }

async function connectWithTwitter(
    oauthAccessToken: string,
    oauthTokenSecret: string,
): Promise<{ userAccount: UserAccount, success: boolean, token: string } | null> {
    const data = JSON.stringify({ oauthAccessToken, oauthTokenSecret });
    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const responseData = await api.helpers.post(`${API_URL}/auth/twitter/connect-with-twitter`, data, customConfig, null, null);
    axios.defaults.headers.common['authorization'] = "Bearer " + responseData.token;

    return responseData;
}

async function fetchConnectedUserAccount(
    optionalToken: string | null = null,
): Promise<UserAccount | null> {
    if (optionalToken !== null) axios.defaults.headers.common['authorization'] = "Bearer " + optionalToken;

    return await api.helpers.get(`${API_URL}/users/user-info/connected`, null, null, "userAccount");
}

async function fetchUserAccount(
    optionalToken: string | null = null,
    userIds: string[] | undefined = undefined
): Promise<UserAccount | UserAccount[] | null> {
    if (optionalToken !== null) axios.defaults.headers.common['authorization'] = "Bearer " + optionalToken;

    return await api.helpers.get(`${API_URL}/users/user-info${userIds !== undefined ? "?" + userIds.map(userId => `userId=${userId}&`).join("") : ""}`, null, null, "userAccount");
}

async function fetchUserAccountByUsername(
    username: string
): Promise<UserAccount | null> {
    return await api.helpers.get(`${API_URL}/users/user-info?username=${username}`, null, null, "userAccount");
}


async function joinBrokerageProgram(
    inviteCode: string | undefined
): Promise<{ success: boolean, error: string | undefined, userAccount: any } | null> {
    const data = JSON.stringify({ inviteCode });
    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return await api.helpers.post(`${API_URL}/users/join-brokerage-program`, data, customConfig, null);
}

// async function getPremintBrokersLeaderboard() {
//     const response = await axios.get(`${API_URL}/users/brokers-leaderboard`);
//     const responseData = response.data;
//     if (!responseData.success) {
//         console.error(responseData.error);
//         return null
//     }

//     // await delay(30000)
//     return responseData.leaderboard;
// }


async function getBrokerInviteInfo(): Promise<{ success: boolean, error: string | undefined, brokerInvites: any } | null> {
    return await api.helpers.get(`${API_URL}/users/user-info/brokerInviteInfo`, null);
}

async function inviteBroker(
    invitedBrokerUsername: string
): Promise<{ success: boolean, error: string | undefined }> {
    const data = JSON.stringify({ invitedBrokerUsername });
    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return await api.helpers.post(`${API_URL}/users/inviteBroker`, data, customConfig, { success: false, error: "An error occured. Try again." });
}


export default {
    // fetchBrokerTree,
    // getUserMessageToSign,
    // loginBrokerAccountWithWallet,
    connectWithTwitter,
    fetchConnectedUserAccount,
    fetchUserAccount,
    fetchUserAccountByUsername,
    joinBrokerageProgram,
    // getPremintBrokersLeaderboard,

    getBrokerInviteInfo,
    inviteBroker,
}