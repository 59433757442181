import "./BrokerCardInfo.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import mixpanel from "mixpanel-browser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faArrowUpFromBracket, faChessKnight } from "@fortawesome/free-solid-svg-icons"

import AddressClicker from "../../../Components/AddressClicker";

import penPlusIcon from "../../../Images/Icons/penPlus.png"

const BrokerCardInfo: FC<any> = (props) => {
    const { loadingContainer, setShowInviteBrokerModal, searchedUserId, userAccount, className } = props

    return (
        !loadingContainer ? <div className={`broker-card-info d-flex flex-column mx-auto my-3 pb-2 ${className ?? ""} ${searchedUserId === userAccount.userId ? "searched" : "not-searched"}`}>
            {/* {<div className="broker-level-icon">{getBrokerLevel(userAccount.userBrokerInfo.points.total)}</div>} */}
            <img src={userAccount.userInfo.pfpUrl} />
            <h5 className="text-white text-center text-luxury mt-4 mb-2">{userAccount.userInfo.username}</h5>
            {searchedUserId === userAccount.userId ? <button className="btn-simple-fill invite-broker-btn d-flex align-items-center my-2 mt-sm-0 px-3 mx-auto w-fit h-fit" onClick={() => { setShowInviteBrokerModal(true); mixpanel.track("Open brokerage invite modal", { topButton: true }) }}><img src={penPlusIcon} style={{ width: "20px" }} height="auto" className="me-2" /><p className="text-luxury text-small mb-0">Invite Broker</p></button> : null}
            <div className="points-info-container d-flex justify-content-between text-white">
                <span className="mx-2">{userAccount.userBrokerInfo.points.lifetime.total}</span>
                <div className="align-items-center mx-2">
                    <a target="_blank" rel="noopener noreferrer" style={{ fontSize: "1rem" }} className="text-white me-2" href={`https://twitter.com/${userAccount.userAuth.twitter.name}`}><FontAwesomeIcon icon={faTwitter} /></a>
                    {/* <AddressClicker address={`${window.location.protocol}//${window.location.hostname}${window.location.port.length > 0 ? ":" + window.location.port : ""}/brokers-globe/account/${userAccount.userAuth.twitter.name}`} component={<FontAwesomeIcon icon={faArrowUpFromBracket} />} /> */}
                    {userAccount.userInfo.isEliteMember && <FontAwesomeIcon icon={faChessKnight} className="elite-member-icon ms-2" title="WCapital Broker" />}
                </div>
            </div>
            {/* {brokerReferrals !== undefined && <div className="points-info-container d-flex justify-content-between text-white">
                <div className="d-flex">
                    <span className="mx-2">{brokerReferrals.directReferrals}</span>
                    <span className="mx-2">{brokerReferrals.extraReferrals}</span>
                </div>
                <span className="mx-2">{brokerReferrals.totalReferrals}</span>
            </div>} */}
        </div>
            : <div className={`broker-card-info d-flex flex-column mx-auto my-3 pb-2 ${className ?? ""}`}>
                <div className="loading-square" style={{ width: "100%", height: 0, paddingBottom: "100%" }}></div>
                <div className="loading-square mx-auto mt-3 mb-4" style={{ width: "150px" }}></div>
                <div className="d-flex justify-content-between text-white px-2">
                    <div className="d-flex">
                        <div className="loading-square" style={{ width: "75px", height: "20px" }}></div>
                        {/* <div className="loading-square ms-2" style={{ width: "20px", height: "20px" }}></div> */}
                    </div>
                    <div className="loading-square ms-auto me-0" style={{ width: "20px", height: "20px" }}></div>
                </div>
            </div>
    )
}

export default BrokerCardInfo