import { createContext, useContext } from "react";

import { UserAccount, BrokerageUserInfo } from "../../utils/types";

export interface BrokerageUserInfoFetch {
    fetching: boolean;
    currentUserBrokerageInfo: BrokerageUserInfo | null;
    fetch: Function;
}

interface UserContextState {
    loggingIn: boolean,
    currentUser: UserAccount | null,
    setCurrentUser: any,
    currentUserBrokerageInfoFetch: BrokerageUserInfoFetch,
    setCurrentUserBrokerageInfoFetch: any,
    login: Function,
    tryReloginWithToken: Function,
    logout: Function
}

const DEFAULT_USER_CONTEXT: UserContextState = {
    loggingIn: false,
    currentUser: null,
    setCurrentUser: null,
    currentUserBrokerageInfoFetch: { fetching: false, currentUserBrokerageInfo: null, fetch: () => null },
    setCurrentUserBrokerageInfoFetch: null,
    login: () => null,
    tryReloginWithToken: () => null,
    logout: () => null,
}

export const UserContext = createContext<UserContextState>(DEFAULT_USER_CONTEXT)

export function useUser(): UserContextState {
    return useContext(UserContext);
}
