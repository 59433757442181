import "./ConnectionButton.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons"

import { useUser } from "./useUser"

import loadingIconAnim from "../../Images/Icons/loadingIcon.gif"

const ConnectionButton: FC<any> = (props) => {
    const { className, withViewAccountButton } = props;

    const { loggingIn, currentUser, login, logout } = useUser();

    function connect() {
        login()
    }


    function disconnect() {
        logout();
    }

    return withViewAccountButton ? (
        <div className="d-flex">
            {currentUser !== null && <button className="btn-default-reversed view-account-btn p-0"><a className="text-black p-2" href={`/brokers-globe/account/info`}><FontAwesomeIcon icon={faUser} /></a></button>}
            <div className={`connection-button ${loggingIn ? "loading" : ""} ${className ?? ""}`}>
                {
                    // loggingIn ? <span>Logging in...</span>
                    loggingIn ? <img src={loadingIconAnim} className="loading-icon-anim" />
                        : currentUser !== null ? <button className="disconnect-btn text-luxury text-black text-bold" onClick={() => disconnect()}>Disconnect</button>
                            : <button className="connect-btn text-luxury text-black text-bold" onClick={() => connect()}>Connect</button>
                }
            </div>
        </div>

    ) : (
        <div className={`connection-button ${loggingIn ? "loading" : ""} ${className ?? ""}`}>
            {
                // loggingIn ? <span>Logging in...</span>
                loggingIn ? <img src={loadingIconAnim} className="loading-icon-anim" />
                    : currentUser !== null ? <button className="disconnect-btn text-luxury text-black text-bold" onClick={() => disconnect()}>Disconnect</button>
                        : <button className="connect-btn text-luxury text-black text-bold" onClick={() => connect()}>Connect</button>
            }
        </div>
    )

}

export default ConnectionButton;
