
import "./BrokerageAccountProfile.css"
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";

import mixpanel from "mixpanel-browser";

import gsap from "gsap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faRotateRight, faChessKnight, faCopy, faUser } from "@fortawesome/free-solid-svg-icons"

import defaultAvatar from "../../../Images/defaultAvatar.png"
import brokerCardImage from "../../../Images/BrokerCard.png";
import { ReactComponent as EthereumIcon } from "../../../Images/Icons/ethereumIcon.svg"
import penPlusIcon from "../../../Images/Icons/penPlus.png"

import ConnectionButton from "../../../Components/Connection/ConnectionButton";
import AddressClicker from "../../../Components/AddressClicker";

import { discordAuthUrl, twitterAuthUrl } from "../../../utils/api/auth/auth"
import { shortenAddress, numberWithCommas, padNumber } from "../../../utils/utils"

import { useUser } from "../../../Components/Connection/useUser";

const BrokerageAccountProfile: FC<any> = (props) => {
    const { setShowInviteBrokerModal } = props;

    const { currentUser } = useUser();

    const brokerCardRef = useRef<HTMLCanvasElement | null>(null)

    useEffect(() => {
        if (currentUser === null || !currentUser.userBrokerInfo.isBroker) return;

        let canvas = document.getElementById("broker-card-canvas");

        let imageUrl = brokerCardImage;

        loadImageOnCanvasAndThenWriteText(
            canvas,
            imageUrl
        );
    }, [currentUser])

    let loadImageOnCanvasAndThenWriteText = (canvas: any, imageUrl: string) => {
        let ctx = canvas.getContext("2d");

        let img = new Image();

        img.onload = async () => {
            // // let loadedImageWidth = img.width;
            // // let loadedImageHeight = img.height;

            // // canvas.width = loadedImageWidth;
            // // canvas.height = loadedImageHeight;

            // // ctx.drawImage(img, 0, 0);
            // // get the scale
            // // it is the min of the 2 ratios
            // let scale_factor = Math.min(canvas.width / img.width, canvas.height / img.height);

            // // Lets get the new width and height based on the scale factor
            // let newWidth = img.width * scale_factor;
            // let newHeight = img.height * scale_factor;

            // // get the top left position of the image
            // // in order to center the image within the canvas
            // let x = (canvas.width / 2) - (newWidth / 2);
            // let y = (canvas.height / 2) - (newHeight / 2);

            // // When drawing the image, we have to scale down the image
            // // width and height in order to fit within the canvas
            // ctx.drawImage(img, x, y, newWidth, newHeight);

            // ctx.textBaseline = "top";

            // function addTextToCanvas(ctx: any, textStyleOptions: any, text: string, xPercentagePosition: number, yPercentagePosition: number) {
            //     ctx.font = `${textStyleOptions.fontSize}px ${textStyleOptions.fontFamily}`;
            //     ctx.fillStyle = textStyleOptions.textColor;
            //     ctx.textAlign = textStyleOptions.textAlign;

            //     const xPosition = xPercentagePosition * newWidth / 100;
            //     const yPosition = yPercentagePosition * newHeight / 100;

            //     ctx.fillText(text, xPosition, yPosition);
            // }

            // let pointsStyleOptions = {
            //     fontSize: 30,
            //     fontFamily: "Perpetua",
            //     textColor: "#cfc0a9",
            //     textAlign: "center"
            // };
            // // addTextToCanvas(ctx, pointsStyleOptions, "???/999", 23.125, 56.3);
            // // addTextToCanvas(ctx, pointsStyleOptions, numberWithCommas(searchedUserFetch.userAccount.userBrokerInfo.points.total), 76.56, 56.3);

            // const usernameStyleOptions = {
            //     fontSize: 20,
            //     fontFamily: "Cinzel Deco Reg",
            //     textColor: "#cfc0a9",
            //     textAlign: "left"
            // }
            // // addTextToCanvas(ctx, usernameStyleOptions, searchedUserFetch.userAccount.userInfo.username, 13.75, 84.5);

            console.log("Creating card")
            let scale_factor = Math.min(canvas.width / img.width, canvas.height / img.height);

            // Lets get the new width and height based on the scale factor
            let newWidth = img.width * scale_factor;
            let newHeight = img.height * scale_factor;

            // get the top left position of the image
            // in order to center the image within the canvas
            let x = (canvas.width / 2) - (newWidth / 2);
            let y = (canvas.height / 2) - (newHeight / 2);

            // When drawing the image, we have to scale down the image
            // width and height in order to fit within the canvas
            ctx.drawImage(img, x, y, newWidth, newHeight);

            ctx.textBaseline = "top";

            const characterToImage: any = {};
            const loadCharacterImagesPromises = [];
            for (let i = 0; i < 36; i++) {
                const character = (i).toString(36).toUpperCase();
                try {
                    // const characterImage = await Jimp.read(`/media/images/BrokerCardLetters/${character}.png`);
                    const promise = new Promise(function (resolve, reject) {
                        const characterImage = new Image();
                        characterImage.onload = () => {
                            characterToImage[character] = characterImage
                            resolve(undefined);
                        };
                        characterImage.src = `/media/images/BrokerCardLetters/${character}.png`;
                    });
                    loadCharacterImagesPromises.push(promise);
                } catch (e) {
                    console.error("Missing image for character: " + character);
                }
            }

            await Promise.all(loadCharacterImagesPromises);
            async function addWordToCanvas(ctx: any, word: string, centerPoint: { x: number, y: number }, textAlignment: "left" | "center" | "right", scale = 1) {
                const wordCharactersImages = word.split("").map((character) => characterToImage[character.toUpperCase()]);
                const wordTotalWidth = wordCharactersImages.reduce((totalWidth: number, characterImage) => totalWidth + characterImage.width * scale, 0);

                let previousCharacterXPos = (centerPoint.x * canvas.width / 100);
                if (textAlignment === "right") wordCharactersImages.reverse();
                else if (textAlignment === "center") previousCharacterXPos -= wordTotalWidth / 2;
                for (const characterImage of wordCharactersImages) {
                    ctx.drawImage(characterImage, textAlignment === "right" ? previousCharacterXPos - characterImage.width * scale : previousCharacterXPos, centerPoint.y * canvas.height / 100, characterImage.width * scale, characterImage.height * scale);
                    previousCharacterXPos += textAlignment === "right" ? -characterImage.width * scale : characterImage.width * scale;
                }
            }

            // addWordToCanvas(ctx, "???/999", { x: 18.75, y: 55 }, "center");
            // addWordToCanvas(ctx, numberWithCommas(currentUser!.userBrokerInfo.points.total), { x: 80, y: 48.6 }, "right", 1.7);
            addWordToCanvas(ctx, currentUser!.userBrokerInfo!.rank !== null ? padNumber(currentUser!.userBrokerInfo!.rank, "0", 3) : "unranked", { x: 24.25, y: 56 }, "center", 0.6);
            addWordToCanvas(ctx, Math.round(currentUser!.userBrokerInfo!.points.lifetime.total).toString(), { x: 75.25, y: 56 }, "center", 0.6);

            addWordToCanvas(ctx, currentUser!.userInfo.username!, { x: 14.56, y: 83.5 }, "left", 0.5);
        };

        // Now that we have set up the image "onload" handeler, we can assign
        // an image URL to the image.
        img.src = imageUrl;
    };

    useEffect(() => {
        document.addEventListener('mousemove', (e) => {
            moveCard(e)
        });

    }, [])

    function moveCard(e: MouseEvent) {
        if (brokerCardRef === null || brokerCardRef.current === null) return;
        const cx = Math.ceil(brokerCardRef.current?.clientWidth / 2.0);
        const cy = Math.ceil(brokerCardRef.current?.clientHeight / 2.0);
        const dx = e.screenX - window.innerWidth / 2;
        const dy = e.screenY - window.innerHeight / 2;

        const tiltx = -(dy / cy);
        const tilty = (dx / cx);
        gsap.set("#broker-card-canvas", {
            transform: `rotateY(${tilty * -2}deg) rotateX(${tiltx * -3}deg)`
            // transform: 'rotate3d(' + tiltx + ', ' + tilty + ', 0, ' + degree + 'deg)'
        });
    }

    return (
        currentUser !== null ? <div id="brokerage-account-profile" className="d-flex flex-column text-dark w-100 mt-4 px-2 px-md-4 px-lg-5 pb-5">
            <h1 className="text-luxury">Profile</h1>
            <div className="d-flex flex-column align-items-center mt-5">
                <img src={currentUser.userInfo.pfpUrl === null ? defaultAvatar : currentUser.userInfo.pfpUrl} id="broker-pfp-img" />
                <div className="d-flex flex-column mt-3">
                    <h5 className="text-luxury broker-text-primary d-flex align-items-center justify-content-center text-center pt-2">{currentUser.userInfo.username} {currentUser.userBrokerInfo.isBroker && <FontAwesomeIcon icon={faChessKnight} className="broker-icon ms-3" title="WCapital Broker" style={{ fontSize: "1.3rem" }} />}</h5>
                    <div className="d-flex align-items-center mt-2 ms-1 justify-content-center">
                        <EthereumIcon width="25px" height="auto" style={{ padding: "0 3px" }} />
                        {currentUser.userAuth.wallet !== null ? <AddressClicker address={currentUser.userAuth.wallet} className="ms-2" component={<span>({shortenAddress(currentUser.userAuth.wallet, 6)})</span>} />
                            : <button className="btn-default-reversed ms-3" disabled>Connect Wallet</button>
                        }
                    </div>
                    <div className="d-flex align-items-center mt-2 ms-1 justify-content-center">
                        <FontAwesomeIcon icon={faDiscord} className="broker-social-icon" style={{ padding: "0 3px" }} />
                        {
                            currentUser.userAuth.discord.name === null ? currentUser.userId === currentUser?.userId ? <a href={discordAuthUrl} className="connect-link text-black ms-3 pe-4"><h6 className="mb-0">Link Account</h6></a> : <span className="ms-3" style={{ color: "grey", fontSize: "0.95rem" }}>Not assigned</span>
                                : <>
                                    <p className="mb-0 ms-3 me-2">{currentUser.userAuth.discord.name}</p>
                                    {currentUser.userId === currentUser?.userId && <a href={discordAuthUrl} className="text-black text-small"><FontAwesomeIcon icon={faRotateRight} /></a>}
                                </>
                        }
                    </div>
                    {<div className="d-flex flex-column flex-sm-row justify-content-center align-items-center mt-4">
                        <p className="mb-0 text-center"><span style={{ textDecoration: "underline" }}>Invite Code:</span> {currentUser.userInfo.username} <AddressClicker address={currentUser.userInfo.username} length={6} className="ms-2" component={<FontAwesomeIcon icon={faCopy} />} /></p>
                        {currentUser.userId === currentUser?.userId && <button className="btn-simple-fill-reversed invite-broker-btn d-flex align-items-center mt-2 mt-sm-0 ms-3 px-3 h-fit" onClick={() => { setShowInviteBrokerModal(true); mixpanel.track("Open brokerage invite modal", { topButton: true }) }}><img src={penPlusIcon} width="20px" height="auto" className="me-2" /><p className="text-luxury text-small mb-0">Invite Broker</p></button>}
                    </div>}
                    {/* {} */}
                    {currentUser.userBrokerInfo.isBroker ? <canvas id="broker-card-canvas" ref={brokerCardRef} width="500px" height="300px" /> : <p className="mt-2 text-center" style={{ color: "#333333" }}>(<span style={{ textDecoration: "underline" }}>Broker Points:</span> {currentUser.userBrokerInfo.points.lifetime.total} | <span style={{ textDecoration: "underline" }}>Rank:</span> {currentUser.userBrokerInfo.rank !== null ? padNumber(currentUser.userBrokerInfo.rank, "0", 3) : "Unranked"})</p>}

                </div>
                <div className="d-flex flex-column mx-auto mt-3 w-fit h-fit">
                    <div className="d-flex">
                        <ConnectionButton />
                    </div>
                </div>
            </div>
        </div> : null
    )
}

export default BrokerageAccountProfile;