import "./BrokerageHome.css"
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";

import brokerageEmblem from "../../Images/Icons/brokerageEmblem.png"
import nycSkylineVideo from "../../Videos/nycSkyline.mp4"
import ConnectionButton from "../../Components/Connection/ConnectionButton";

const BrokerageHome: FC<any> = (props) => {

    const history = useHistory()

    return (
        <div id="brokerage-home" className="d-flex flex-column align-items-center" style={{ maxHeight: window.innerHeight }}>
            <video id="brokerage-home-video" src={nycSkylineVideo} width="auto" height="auto" muted={true} autoPlay={true} loop={true} playsInline={true} />
            <div id="brokerage-emblem-container" className="d-flex flex-column align-items-center">
                <img src={brokerageEmblem} width="110px" height="auto" />
                <h4 className="text-luxury text-center mt-2 mx-4">Westmont Capital Brokerage</h4>
            </div>
            <div id="brokerage-enter-container" className="d-flex flex-column align-items-center">
                <h4 className="text-normal mb-4">Access to the Brokerage is by invitation only.</h4>
                <ConnectionButton />
                <button onClick={(e) => history.push("/brokerage/join")} className="btn-simple text-normal-base-size mt-1">Become a member</button>
            </div>
        </div>
    )
}

export default BrokerageHome;