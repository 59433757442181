import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import { useTwitterOauth2Popup } from "../../../Components/useTwitterOauth2Popup"

import dailyGm0 from "../../../Images/dailyGms/0.gif";
import dailyGm1 from "../../../Images/dailyGms/1.gif";
import dailyGm2 from "../../../Images/dailyGms/2.gif";
import dailyGm3 from "../../../Images/dailyGms/3.gif";
import dailyGm4 from "../../../Images/dailyGms/4.gif";
import dailyGm5 from "../../../Images/dailyGms/5.gif";
import dailyGm6 from "../../../Images/dailyGms/6.gif";
import loadingIconTransparentAnim from "../../../Images/Icons/loadingIconTransparent.gif";


import api from "../../../utils/api/api"
import { delay, getTimeDifferenceFromDelta, transformBucketTimeDifferenceToClock } from "../../../utils/utils";
import { useUser } from "../../../Components/Connection/useUser";

const dailyGmImages = [dailyGm0, dailyGm1, dailyGm2, dailyGm3, dailyGm4, dailyGm5, dailyGm6]

const BrokerageDailyGm: FC<any> = (props) => {
    const { className, style } = props;

    const { currentUser, currentUserBrokerageInfoFetch, setCurrentUserBrokerageInfoFetch } = useUser();

    const [dailyGmImage, setDailyGmImage] = useState()
    const [waitingForWritePermissions, setWaitingForWritePermissions] = useState(false)
    const [tweetingDailyGm, setTweetingDailyGm] = useState(false)
    const [timeNextBeforeNextGm, setTimeNextBeforeNextGm] = useState<number>()

    const { oauthSetupResult, openPopup } = useTwitterOauth2Popup();

    useEffect(() => {
        const currentDate = new Date();
        const indexOfTheWeek = currentDate.getDay();

        setDailyGmImage(dailyGmImages[indexOfTheWeek]);
    }, [])

    async function tweetGm() {
        setWaitingForWritePermissions(false);
        setTweetingDailyGm(true);
        const firstGmResult = await api.brokerage.tweetDailyGm();
        if (!firstGmResult.success) {
            console.error(firstGmResult.error);
            if (firstGmResult.error.toString().toLowerCase().includes("token")) {
                openPopup();
                setWaitingForWritePermissions(true);
            } else {
                toast.error(firstGmResult.error);
            }
            return;
        }
        setTweetingDailyGm(false);

        toast.success(<a href={`https://twitter.com/${currentUser?.userAuth.twitter.name}/status/${firstGmResult.tweetId}`} target="_blank" rel="noopener noreferrer" className="text-white text-normal-base-size">Tweeted GM! <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ms-2" /></a>)
        const todayTimestamp = new Date().setUTCHours(0, 0, 0, 0);
        if (currentUserBrokerageInfoFetch.currentUserBrokerageInfo !== null) setCurrentUserBrokerageInfoFetch({ ...currentUserBrokerageInfoFetch, currentUserBrokerageInfo: { ...currentUserBrokerageInfoFetch.currentUserBrokerageInfo, gms: { currentStreak: currentUserBrokerageInfoFetch.currentUserBrokerageInfo.gms.currentStreak + 1, latestGmTimestamp: todayTimestamp } } });
    }

    useEffect(() => {
        if (waitingForWritePermissions) {
            if (oauthSetupResult?.success) tweetGm()
            if (!oauthSetupResult?.success) setTweetingDailyGm(false);
        }
    }, [oauthSetupResult])


    useEffect(() => {
        if (currentUserBrokerageInfoFetch.currentUserBrokerageInfo !== null) {
            if (Date.now() - currentUserBrokerageInfoFetch.currentUserBrokerageInfo.gms.latestGmTimestamp >= 2 * 86_400_000 && currentUserBrokerageInfoFetch.currentUserBrokerageInfo.gms.currentStreak > 0) setCurrentUserBrokerageInfoFetch({ ...currentUserBrokerageInfoFetch, currentUserBrokerageInfo: { ...currentUserBrokerageInfoFetch.currentUserBrokerageInfo, gms: { ...currentUserBrokerageInfoFetch.currentUserBrokerageInfo.gms, currentStreak: 0 } } });
        }
    }, [currentUserBrokerageInfoFetch.currentUserBrokerageInfo])

    useEffect(() => {
        updateTimeLeftBeforeNextGm();
    }, [currentUserBrokerageInfoFetch.currentUserBrokerageInfo, timeNextBeforeNextGm])
    async function updateTimeLeftBeforeNextGm() {
        if (currentUserBrokerageInfoFetch.currentUserBrokerageInfo !== null) {
            if (timeNextBeforeNextGm !== 0 && timeNextBeforeNextGm !== undefined) await delay(1000);
            const diff = currentUserBrokerageInfoFetch.currentUserBrokerageInfo.gms.latestGmTimestamp + 86_400_000 - Date.now();
            // if (diff <= -86_400_000 && currentUserBrokerageInfo?.gms.currentStreak > 0) {
            //     setCurrentUserBrokerageInfo({ ...currentUserBrokerageInfo, gms: { ...currentUserBrokerageInfo.gms, currentStreak: 0 } });
            //     return;
            // }
            setTimeNextBeforeNextGm(Math.max(diff, 0));
        } else {
            setTimeNextBeforeNextGm(undefined)
        }
    }

    return (
        <div id="daily-gm" className={`d-flex justify-content-center ${className ?? ""} w-100`} style={style}>
            <div className="d-none d-xxl-flex align-items-center col-5 pe-3">
                <img src={dailyGmImage} width="100%" height="auto" />
            </div>
            <div className="d-flex flex-column justify-content-between col-12 col-sm-10 col-md-8 col-lg-7">
                <h3 className="text-normal mb-0 mx-auto">GM Streaks</h3>
                <div className="d-flex d-xxl-none align-items-center col-8 col-sm-6 col-lg-5 pe-xxl-3 mx-auto">
                    <img src={dailyGmImage} width="100%" height="auto" />
                </div>
                {currentUserBrokerageInfoFetch.fetching ? <div className="loading-square" style={{ width: "60px", height: "25px", margin: "0.5rem auto", background: "var(--color-third)" }}></div> : <span className="text-center my-2">{currentUserBrokerageInfoFetch.currentUserBrokerageInfo?.gms.currentStreak} GMs</span>}
                <button id="tweet-gm-btn" className="btn-simple-fill-reversed text-normal-base-size" onClick={(e) => tweetGm()} disabled={timeNextBeforeNextGm !== 0}>{tweetingDailyGm ? <img src={loadingIconTransparentAnim} className="loading-icon-anim" /> : timeNextBeforeNextGm === undefined ? "TWEET GM" : timeNextBeforeNextGm <= 0 ? "TWEET GM" : transformBucketTimeDifferenceToClock(getTimeDifferenceFromDelta(timeNextBeforeNextGm), true)}</button>

            </div>
        </div>
    )
}

export default BrokerageDailyGm;
