import axios from "axios";

import {RoyaltyShareInfo} from "../../types"
import {delay} from "../../utils"
import {API_URL} from "../api"

async function fetchRoyaltyShareInfo(): Promise<RoyaltyShareInfo|null> {
    const response = await axios.get(`${API_URL}/royalty-share/info`);
    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return null
    }

    responseData.royaltyShareInfo.nextPayoutTimestamp = responseData.royaltyShareInfo.nextPayoutTimestamp._seconds;
    return responseData.royaltyShareInfo;
}


export default {
    fetchRoyaltyShareInfo
}