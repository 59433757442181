import "./Brokerage.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';

import ReactPlayer from 'react-player/youtube'

import Navbar from "../../Components/Navbar"
import BrokerageHome from "./BrokerageHome";
import BrokerageJoin from "./BrokerageJoin";
import BrokerageAccount from "./BrokerageAccount";

import musicIcon from "../../Images/Icons/musicIcon.png";
import musicIconMute from "../../Images/Icons/musicIconMute.png";
import { useUser } from "../../Components/Connection/useUser";

enum BrokeragePage {
    HOME,
    ACCOUNT,
    JOIN
}

const Brokerage: FC<any> = (props) => {
    const { locationPaths } = props

    const history = useHistory();

    const { currentUser, loggingIn, tryReloginWithToken } = useUser();

    const [musicInfo, setMusicInfo] = useState({ volume: .5, isMuted: true, isPlaying: true, controlsSelected: false, loaded: false })
    const [page, setPage] = useState<BrokeragePage>(BrokeragePage.HOME);

    useEffect(() => {
        tryReloginWithToken()
    }, [])

    useEffect(() => {
        if (loggingIn) return;
        if (currentUser === null) {
            if (locationPaths[1] === "account") history.push("/brokerage")
        }
        else if (currentUser!.isMember) {
            if (locationPaths[1] !== "account") history.push("/brokerage/account")
        }
        else {
            if (locationPaths[1] !== "join") history.push("/brokerage/join")
        }
    }, [currentUser, loggingIn, locationPaths])

    useEffect(() => {
        console.log(locationPaths[1])
        if (locationPaths[1] === "account") {
            setPage(BrokeragePage.ACCOUNT);
            return
        }
        if (locationPaths[1] === "join") {
            setPage(BrokeragePage.JOIN);
            return
        }
        setPage(BrokeragePage.HOME);
    }, [locationPaths])

    return (
        <div id="brokerage" className="d-flex flex-column" style={page === BrokeragePage.JOIN ? { paddingTop: "10rem" } : {}}>
            <Navbar transparent={page !== BrokeragePage.JOIN} fillOnScrollUp={true} />
            {
                page === BrokeragePage.HOME ? <BrokerageHome /> :
                    page === BrokeragePage.JOIN ? <BrokerageJoin /> :
                        page === BrokeragePage.ACCOUNT ? <BrokerageAccount locationPaths={locationPaths} /> :
                            // page === BrokeragePage.ACCOUNT ? <BrokersGlobeAccount pageName={locationPaths[2]} extraLocationsPaths={locationPaths.slice(3, locationPaths.length)} /> :
                            // page === GlobePage.INFO ? <BrokersGlobeInfo searchedNft={searchedNft} /> : HNxf0cSOwio
                            null
            }

            {!isMobile && <><ReactPlayer id="music-player" url='https://www.youtube.com/watch?v=cut-un_nbC0' onReady={() => setMusicInfo({ ...musicInfo, loaded: true })} playing={musicInfo.isPlaying} loop={true} playsInline={true} volume={musicInfo.volume} muted={musicInfo.isMuted} />
                {musicInfo.loaded && <div id="music-controller" onMouseEnter={() => setMusicInfo({ ...musicInfo, controlsSelected: true })} onMouseLeave={() => setMusicInfo({ ...musicInfo, controlsSelected: false })}>
                    <button className="btn-simple" onClick={() => setMusicInfo({ ...musicInfo, isMuted: !musicInfo.isMuted })}><img src={musicInfo.isMuted ? musicIconMute : musicIcon} /></button>
                </div>}
            </>}
        </div>
    )
}

export default Brokerage;
