import "./InviteBrokerModal.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import mixpanel from "mixpanel-browser";

import Modal from "react-bootstrap/Modal"
import { toast } from "react-toastify";

import loadingIcon from "../../../Images/Icons/loadingIcon.gif"

import api from "../../../utils/api/api";
import { delay, getTimeDifference, transformBucketTimeDifferenceToString } from "../../../utils/utils";
import { INVITES_CONFIG_PER_POINTS } from "../../../utils/constants";
import { useUser } from "../../../Components/Connection/useUser";

function getInvitesInfoInTimespanCycle(brokerInvites: any[], totalBrokerPoints: number): { numInvitesInTimespanCycle: number, maxInvitesInTimespan: number } {
    const currentTimestamp = Date.now();
    let timespanCycleStart = 1678057200000; // March 1st 2023
    const userInvitesConfig = getUserInvitesConfig(totalBrokerPoints);
    while (timespanCycleStart < currentTimestamp) {
        timespanCycleStart += userInvitesConfig.timespan;
    }
    timespanCycleStart -= userInvitesConfig.timespan;

    const invitesInTimespanCycle = brokerInvites.filter((invite: any) => invite.inviteTime >= timespanCycleStart);
    return { numInvitesInTimespanCycle: invitesInTimespanCycle.length, maxInvitesInTimespan: userInvitesConfig.maxInvites };
}
function getUserInvitesConfig(totalPoints: number) {
    const possibleConfigs = [];
    for (const pointInvitesConfig of INVITES_CONFIG_PER_POINTS) {
        if (pointInvitesConfig.minPoints <= totalPoints) possibleConfigs.push(pointInvitesConfig);
    }
    return possibleConfigs[possibleConfigs.length - 1];
}


interface InviteInfoFetch {
    fetching: boolean;
    inviteInfo: { numInvitesInTimespanCycle: number, maxInvitesInTimespan: number } | null;
    fetch: Function
}

const InviteBrokerModal: FC<any> = (props) => {
    const { show, onHide } = props;

    const { currentUser } = useUser();

    const [inviteInfoFetch, setInviteInfoFetch] = useState<InviteInfoFetch>({ fetching: false, inviteInfo: null, fetch: fetchInviteInfo })
    const [startInvitingTimestamp, setStartInviteTimestamp] = useState<number | null>(null)

    const [newInviteInfoUsername, setNewInviteInfoUsername] = useState("")
    const [isSendingInvite, setIsSendingInvite] = useState(false)

    useEffect(() => {
        if (show && inviteInfoFetch.inviteInfo === null) fetchInviteInfo()
    }, [show])

    useEffect(() => {
        if (!show || startInvitingTimestamp === null) return;
        async function decrement() {
            await delay(1000);
            const newStartInvitingTimestamp = startInvitingTimestamp! - 1000;
            if (newStartInvitingTimestamp > 0) {
                setStartInviteTimestamp(newStartInvitingTimestamp)
                return;
            }
            setStartInviteTimestamp(null)
            fetchInviteInfo()
        }
        decrement();
    }, [startInvitingTimestamp])

    async function fetchInviteInfo() {
        setInviteInfoFetch({ ...inviteInfoFetch, fetching: true })
        const response = await api.users.getBrokerInviteInfo();
        if (!response?.success) {
            toast.error(response?.error);
            setInviteInfoFetch({ ...inviteInfoFetch, fetching: false, inviteInfo: null })
            return;
        }

        setInviteInfoFetch({ ...inviteInfoFetch, fetching: false, inviteInfo: getInvitesInfoInTimespanCycle(response.brokerInvites, currentUser?.userBrokerInfo?.points.lifetime.total ?? 0) });
    }

    async function sendInvite() {
        if (inviteInfoFetch.inviteInfo !== null && inviteInfoFetch.inviteInfo.numInvitesInTimespanCycle >= inviteInfoFetch.inviteInfo.maxInvitesInTimespan) return;

        setIsSendingInvite(true);
        const response = await api.users.inviteBroker(newInviteInfoUsername)
        mixpanel.track("Sent Brokerage invite", { numInvitesInTimespanCycle: inviteInfoFetch.inviteInfo?.numInvitesInTimespanCycle, maxInvitesInTimespan: inviteInfoFetch.inviteInfo?.maxInvitesInTimespan })
        setIsSendingInvite(false)
        if (!response.success) {
            toast.error(response.error);
            return;
        }
        toast.success(`Invited user "${newInviteInfoUsername}"`)
        inviteInfoFetch.inviteInfo !== null && setInviteInfoFetch({ ...inviteInfoFetch, inviteInfo: { ...inviteInfoFetch.inviteInfo, numInvitesInTimespanCycle: inviteInfoFetch.inviteInfo?.numInvitesInTimespanCycle + 1 } })
        setNewInviteInfoUsername("")
    }

    return (
        <Modal show={show} onHide={onHide} size="lg" centered id="invite-broker-modal" className="d-flex flex-column align-items-center">
            <Modal.Body className="d-flex flex-column text-center">
                <h2 className="text-luxury">Invite Broker</h2>
                <p>As a member of the Brokerage Program, you are able to invite individuals to become future brokers.<br />
                    The quality of brokers you invite plays a substantial role in your overall broker points and your invites are limited, so be sure to be very selective on who you invite.</p>
                <input type="text" placeholder="Twitter username..." id="invite-broker-username-input" className="text-normal-base-size ps-2 mt-4" value={newInviteInfoUsername} onChange={(e) => setNewInviteInfoUsername(e.target.value)} />
                {inviteInfoFetch.fetching ? <div className="loading-square m-0 mt-2 ms-auto" style={{ width: "100px", height: "25px" }}></div>
                    : startInvitingTimestamp !== null ? <p className="text-end mt-2">Membership invites available for you in <b>{transformBucketTimeDifferenceToString(getTimeDifference(0, startInvitingTimestamp))}</b></p>
                        : <p className={`text-end ${inviteInfoFetch.inviteInfo !== null && inviteInfoFetch.inviteInfo.numInvitesInTimespanCycle >= inviteInfoFetch.inviteInfo.maxInvitesInTimespan ? "text-error" : ""} mt-1 mb-1`}>{inviteInfoFetch.inviteInfo?.numInvitesInTimespanCycle}/{inviteInfoFetch.inviteInfo?.maxInvitesInTimespan} Invites</p>}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn-default-reversed d-flex px-3 py-1" onClick={sendInvite} disabled={newInviteInfoUsername.length <= 0 || isSendingInvite || inviteInfoFetch.fetching || (inviteInfoFetch.inviteInfo !== null && inviteInfoFetch.inviteInfo.numInvitesInTimespanCycle >= inviteInfoFetch.inviteInfo.maxInvitesInTimespan)}>{isSendingInvite ? <img src={loadingIcon} width="20px" height="auto" style={{ margin: ".1rem .55rem" }} /> : "Invite"}</button>
            </Modal.Footer>
        </Modal>
    )
}

export default InviteBrokerModal;