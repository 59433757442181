import axios from "axios";

import { API_URL } from "../api"

const discordServerID = "1024429925157052449"

async function getDiscordInfoFromCode(code: string): Promise<any> {
    console.log(axios.defaults.headers.common)
    let response;
    try {
        response = await axios.get(`${API_URL}/auth/discord/request_access_token?code=${code}`, { withCredentials: true });
    } catch (err) {
        console.error(err);
        return null;
    }
    const responseData = response.data;

    if (!responseData.success) {
        console.error(response);
        return responseData;
    }

    return responseData;

    // const infoResponse = await axios.get(`${API_URL}/auth/discord/info`, {withCredentials: true});
    // const infoResponseData = infoResponse.data
    // const discordInfo = {
    //     name: infoResponseData.username + "#" + infoResponseData.discriminator,
    //     following: infoResponseData.guilds.filter((guild: any) => guild.id === discordServerID).length !== 0
    // }

    // return discordInfo;
}

export default {
    getDiscordInfoFromCode
}