import "./BrokerageJoin.css"
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCircleCheck, faCircleXmark, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

import PopoverContainer, { PopoverTriggerType } from '@idui/react-popover'

import gsap from "gsap";
import mixpanel from "mixpanel-browser";
import { toast } from "react-toastify";

import brokerageEmblem from "../../Images/Icons/brokerageEmblem.png"
import loadingIconAnim from "../../Images/Icons/loadingIcon.gif"
import ConnectionButton from "../../Components/Connection/ConnectionButton";

import api from "../../utils/api/api";
import { delay } from "../../utils/utils";
import { useUser } from "../../Components/Connection/useUser";

const BROKER_CODE_MAX_LENGTH = 20;

const BrokerageJoin: FC<any> = (props) => {

    const history = useHistory();
    const { currentUser, setCurrentUser, logout } = useUser();

    const [inviteBrokerCodeInUse, setInviteBrokerCodeInUse] = useState<string | undefined>()
    const [canJoinBrokerage, setCanJoinBrokerage] = useState(false)

    const [refreshingTwitterFollowingStatus, setRefreshingTwitterFollowingStatus] = useState({ isRefreshing: false, timerLeft: 0 })
    const [joiningBrokerageProgram, setJoiningBrokerageProgram] = useState(false)


    useEffect(() => {
        if (currentUser !== null && currentUser.userAuth.twitter.name !== null && inviteBrokerCodeInUse !== undefined && inviteBrokerCodeInUse.length > 0 && !currentUser.isMember) {
            setCanJoinBrokerage(true)
            return;
        }
        setCanJoinBrokerage(false)
    }, [currentUser, inviteBrokerCodeInUse])

    async function refreshTwitterFollowingStatus() {
        if (currentUser === null) return;

        gsap.from("#refresh-twitter-following-btn", { rotationZ: -360, duration: .6 })

        setRefreshingTwitterFollowingStatus({ ...refreshingTwitterFollowingStatus, isRefreshing: true });
        const response = await api.auth.twitter.refreshFollowingStatus();
        setRefreshingTwitterFollowingStatus({ isRefreshing: true, timerLeft: 5 });
        if (response === null || !response.success) {
            mixpanel.track("Join Brokerage Page: refresh twitter following status", { isFollowing: false })
            toast.error(response?.error ?? "An error occured. Please try connecting again.");
            logout();
            return;
        }
        const isFollowing = response.isFollowing;
        mixpanel.track("Join Brokerage Page: refresh twitter following status", { isFollowing })
        setCurrentUser({ ...currentUser, userAuth: { ...currentUser.userAuth, twitter: { ...currentUser.userAuth.twitter, following: isFollowing } } });
    }

    useEffect(() => {
        if (refreshingTwitterFollowingStatus.timerLeft <= 0) return;
        async function decrement() {
            await delay(1000)
            const timerLeft = refreshingTwitterFollowingStatus.timerLeft - 1;
            setRefreshingTwitterFollowingStatus({ isRefreshing: timerLeft > 0, timerLeft });
        }
        decrement()
    }, [refreshingTwitterFollowingStatus.timerLeft])

    async function joinBrokerageProgram() {
        if (!canJoinBrokerage || joiningBrokerageProgram) return;

        setJoiningBrokerageProgram(true);
        const result = await api.users.joinBrokerageProgram(inviteBrokerCodeInUse);
        setJoiningBrokerageProgram(false);
        if (result === null) {
            mixpanel.track("Join Brokerage Page: click join brokerage", { joined: false, inviteBrokerCodeInUse })
            toast.error("An error occured while creating account. Try again.");
            return;
        }
        if (!result.success) {
            mixpanel.track("Join Brokerage Page: click join brokerage", { joined: false, inviteBrokerCodeInUse })
            console.error(result.error);
            toast.error(result.error);
            return;
        }
        setCurrentUser(result.userAccount);
        mixpanel.track("Join Brokerage Page: click join brokerage", { joined: true, inviteBrokerCodeInUse })
        toast.success("Welcome to Westmont Capital Brokerage.")

        history.push("/brokerage/account")
    }

    const refreshTwitterStatusButton = <button id="refresh-twitter-following-btn" className="btn-simple ms-1" onClick={refreshTwitterFollowingStatus} disabled={refreshingTwitterFollowingStatus.isRefreshing || currentUser?.userAuth.twitter.following}><FontAwesomeIcon icon={faArrowRotateRight} /></button>


    return (
        <div id="brokerage-join" className="d-flex flex-column align-items-center px-2">
            <img src={brokerageEmblem} width="125px" height="auto" />
            <h3 className="text-luxury text-center mt-2">Westmont Capital Brokerage</h3>
            <p className="text-center text-normal-base-size mt-3 px-2" style={{ lineHeight: "120%" }}>To enter the brokerage, you need to be invited by a member.<br /><span style={{ fontSize: "1rem" }}>Don't know anyone? <u>Apply to join.</u></span></p>
            {currentUser ? <div className="d-flex flex-column align-items-center mt-5">
                <div className="d-flex align-items-center me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                    <h4 className="text-normal my-0 ms-2 mb-0">{currentUser.userAuth.twitter.name}</h4>
                    <button className="btn-simple ms-2" onClick={() => logout()}>
                        <span className="text-normal" style={{ fontSize: ".8rem" }}>(disconnect)</span>
                    </button>
                </div>

                <input type="text" placeholder="Inviter username..." className="input-simple mt-4" value={inviteBrokerCodeInUse} onChange={(e) => setInviteBrokerCodeInUse(e.target.value.substring(0, BROKER_CODE_MAX_LENGTH))} style={{ fontSize: "1.5rem", paddingLeft: ".5rem", textAlign: "center" }} />

                <button id="create-account-btn" className={`text-luxury text-bold m-3 py-2 px-3 ${joiningBrokerageProgram ? "loading" : ""}`} disabled={!canJoinBrokerage || joiningBrokerageProgram} onClick={joinBrokerageProgram}>
                    {joiningBrokerageProgram ? <img src={loadingIconAnim} className="loading-icon-anim" />
                        : <h4 className="mb-0">Create Account</h4>}
                </button>
                <div className="d-flex flex-column align-items-start ">
                    <h5 className="text-normal mx-auto text-underline mb-0">Requirements:</h5>
                    <span style={{ fontSize: ".95rem" }}>
                        1. <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/follow?screen_name=WolfofEtherSt">Follow us @WolfofEtherSt</a>
                        <FontAwesomeIcon icon={currentUser.userAuth.twitter.following ? faCircleCheck : faCircleXmark} className={`ms-2 ${currentUser.userAuth.twitter.following ? "text-selection" : "text-error"}`} />
                        {refreshingTwitterFollowingStatus.timerLeft > 0 ? <PopoverContainer className="refresh-timer-popover" content={`Try again (${refreshingTwitterFollowingStatus.timerLeft}s)`} trigger={"hover" as PopoverTriggerType} animation={{ animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } }, initial: { opacity: 0, scale: 0.9 } }} closeOnEnter fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck() { }} onFocus={function noRefCheck() { }} withArrow={false} >
                            {refreshTwitterStatusButton}
                        </PopoverContainer> : refreshTwitterStatusButton}
                    </span>
                    <span style={{ fontSize: ".95rem" }}>2. Enter your inviter's name <FontAwesomeIcon icon={inviteBrokerCodeInUse !== undefined && inviteBrokerCodeInUse.length > 0 ? faCircleCheck : faCircleXmark} className={`ms-2 ps-1 ${inviteBrokerCodeInUse !== undefined && inviteBrokerCodeInUse.length > 0 ? "text-selection" : "text-error"}`} /></span>
                </div>
            </div> : <ConnectionButton />}
        </div>
    )
}

export default BrokerageJoin;